import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProductGallerySlider = ({ images }) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false
    };

    return (
        <>
            <Slider {...settings} className="m-b5 primary btn-style-2" id="sync1">
                {images.map((imageUrl, index) => (
                    <div className="item" key={index}>
                        <div className="mfp-gallery">
                            <div className="dlab-box">
                                <div className="dlab-thum-bx dlab-img-overlay1 ">
                                    <img src={`https://rest.alcumusbd.org/abc/${imageUrl?.url}`} alt="" />
                                    <div className="overlay-bx">
                                        <div className="overlay-icon">
                                            <span data-exthumbimage={`https://rest.alcumusbd.org/abc/${imageUrl?.url}`} data-src={`https://rest.alcumusbd.org/abc/${imageUrl?.url}`} className="check-km" title={`Image ${index + 1} Title will come here`}>
                                                <i className="ti-fullscreen"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </>
    );
};

export default ProductGallerySlider;
