import React, { Component, useEffect, useState } from 'react';

import Header from '../layout/header12'
import Footer from '../layout/footer4'
import ProductSlider from '../element/productSlider';
import ProductGallerySlider from '../element/productGallerySlider';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link, useParams } from 'react-router-dom';
import { getCourseById, getUserById } from '../server';
// import 'react-tabs/style/react-tabs.css';


const bg = require('../../images/banner/bnr5.jpg');

const ShopProductDetails = () => {

    const [courseData,setCourseData] = useState({});
    const [certificateNo,setCertificateNo] = useState('');

    const {id} = useParams();
    console.log("id",id);

   

    useEffect(() => {
      if(id){
        getCourseById(id)
            .then((res) => {
                console.log('res', res);
                if (res) {
                    setCourseData(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
    }, [id])
    
    return (
        <>
            <Header />
            <div className="page-content bg-white">
                <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: `url(${bg})` }}>
                    <div className="container">
                        <div className="dlab-bnr-inr-entry">
                            <h1 className="text-white">Course Details</h1>
                         
                            <div className="breadcrumb-row">
                                <ul className="list-inline">
                                    <li><Link to="/">Home</Link></li>
                                    <li>Course Details</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-full content-inner bg-white">
                    <div className="container woo-entry">
                        <div className="row m-b30">
                            <div className="col-md-5 col-lg-5 col-sm-12 m-b30">
                              {
                                courseData && courseData.images && <ProductGallerySlider images={courseData.images} />
                              }
                            </div>
                        {
                            courseData && (
                                <div className="col-md-7 col-lg-7 col-sm-12">
                                <form method="post" className="cart sticky-top">
                                    <div className="dlab-post-title">
                                        <h4 className="post-title"><Link to="#">Course Title</Link></h4>
                                        <p className="m-b10">{courseData?.title}</p>
                                        <div className="dlab-divider bg-gray tb15">
                                            <i className="icon-dot c-square"></i>
                                        </div>
                                    </div>
                                    <div className="relative">
                                        <h3 className="m-tb10">TK {courseData?.price} </h3>
                                        {/* <div className="shop-item-rating">
                                            <span className="rating-bx">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star-o"></i>
                                                <i className="fa fa-star-o"></i>
                                            </span>
                                            <span>4.5 Rating</span>
                                        </div> */}
                                    </div>
                                    <div className="shop-item-tage">
                                        <span>Tags :- </span>
                                        <Link to="#">Courses,</Link>
                                        <Link to="#">{courseData?.type},</Link>
                                        <Link to="#">Training</Link>
                                    </div>
                                    <div className="dlab-divider bg-gray tb15">
                                        <i className="icon-dot c-square"></i>
                                    </div>
                                    {/* <div className="row">
                                        <div className="m-b30 col-md-7 col-sm-8">
                                            <h6>Product Size</h6>
                                            <div className="btn-group product-item-size" data-toggle="buttons">
                                                <label className="btn active">
                                                    <input type="radio" name="options" id="option1" defaultChecked />XS
                                                </label>
                                                <label className="btn">
                                                    <input type="radio" name="options" id="option2" /> LG
                                                </label>
                                                <label className="btn">
                                                    <input type="radio" name="options" id="option3" /> MD
                                                </label>
                                                <label className="btn">
                                                    <input type="radio" name="options" id="option4" /> SM
                                                </label>
                                                <label className="btn">
                                                    <input type="radio" name="options" id="option5" /> XL
                                                </label>
                                            </div>
                                        </div>
                                        <div className="m-b30 col-md-5 col-sm-4">
                                            <h6>Select quantity</h6>
                                            <div className="quantity btn-quantity style-1">
                                                <input id="demo_vertical2" type="text" defaultValue="1" className="form-control" name="demo_vertical2" />
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="m-b30">
                                        <h6>Select the color</h6>
                                        <div className="btn-group product-item-color" data-toggle="buttons">
                                            <label className="btn bg-red active">
                                                <input type="radio" name="options" id="option6" defaultChecked />
                                            </label>
                                            <label className="btn bg-pink">
                                                <input type="radio" name="options" id="option7" />
                                            </label>
                                            <label className="btn bg-yellow">
                                                <input type="radio" name="options" id="option8" />
                                            </label>
                                            <label className="btn bg-blue">
                                                <input type="radio" name="options" id="option9" />
                                            </label>
                                            <label className="btn bg-green">
                                                <input type="radio" name="options" id="option10" />
                                            </label>
                                        </div>
                                    </div> */}
                                    <button className="site-button radius-no">
                                        <i className="ti-shopping-cart"></i> Buy This Course
                                    </button>
                                </form>
                            </div>
                            )
                        }
                        </div>
                        <div className="row m-b30">
                            <div className="col-lg-12">
                                <div className="dlab-tabs product-description tabs-site-button">
                                    <Tabs>
                                        <TabList className="nav nav-tabs">
                                            <Tab selectedClassName="tab-active">
                                                <Link to="#">
                                                    <i className="fa fa-globe"></i> Description
                                                </Link>
                                            </Tab>
                                            <Tab selectedClassName="tab-active">
                                                <Link to="#">
                                                    <i className="fa fa-photo"></i> Additional Information
                                                </Link>
                                            </Tab>
                                            <Tab selectedClassName="tab-active">
                                                <Link to="#g">
                                                    <i className="fa fa-cog"></i> Video Preview
                                                </Link>
                                            </Tab>
                                        </TabList>

                                        <TabPanel className="tab-pane">
                                            <p>{courseData?.shortDescription}</p>
                                        </TabPanel>

                                        <TabPanel className="tab-pane">
                                            <table className="table table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <td>Title</td>
                                                        <td>{
                                                            courseData?.title
                                                            }</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Type</td>
                                                        <td>{courseData?.type}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Pricing</td>
                                                        <td>
                                                            {courseData?.price}
                                                        </td>
                                                    </tr>
                                                    {/* <tr>
                                                        <td>Waist</td>
                                                        <td>26 cm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Length</td>
                                                        <td>40 cm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Chest</td>
                                                        <td>33 inches</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Fabric</td>
                                                        <td>Cotton, Silk & Synthetic</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Warranty</td>
                                                        <td>3 Months</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Chest</td>
                                                        <td>33 inches</td>
                                                    </tr> */}
                                                </tbody>
                                            </table>
                                        </TabPanel>

                                        <TabPanel className="tab-pane">
                                            <div id="comments">
                                            <div className="row project-video-wrapper">
                                        <div className="col-12">
                                          {courseData?.video?.url ? (
                                            <video
                                              controls
                                              width="640"
                                              height="360"
                                            >
                                               
                                              <source
                                                src= {`https://rest.alcumusbd.org/abc/${courseData?.video?.url}`}
                                                type="video/mp4"
                                              />
                                              Your browser does not support the
                                              video tag.
                                            </video>
                                          ) : null}
                                        </div>
                                      </div>
                                            </div>
                                            <div id="review_form_wrapper">
                                                {/* Review form */}
                                            </div>
                                        </TabPanel>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-lg-12">
                                <h5 className="m-b20">Related Products</h5>
                                <ProductSlider />
                            </div>
                        </div> */}
                    </div>
                </div>

                {/* <div className="section-full p-t50 p-b20 bg-primary text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-lg-4">
                               
                            </div>
                            <div className="col-md-4 col-lg-4">
                                
                            </div>
                            <div className="col-md-4 col-lg-4">
                            
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <Footer  />
        </>
    );
};

export default ShopProductDetails;
