import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
//import responsive from './slider-resonsive'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import moment from 'moment/moment';

function BlogSlider1({ data, imgEffect, blogPostCls }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings} className="blog-carousel btn-style-1">
        {data &&
          data.map((item, id) => (
            <div className="item">
              <div class={`blog-post ${blogPostCls}`}>
                <div class={`dlab-post-media dlab-img-effect ${imgEffect}`}>
                  {' '}
                  <Link to="#">
                    <img
                      src={`https://rest.alcumusbd.org/abc/${item.image.url}`}
                      alt=""
                    />
                  </Link>{' '}
                </div>
                <div className="dlab-post-info">
                  <div className="dlab-post-meta">
                    <ul>
                      <li className="post-date">
                        {' '}
                        <strong>
                          {moment(item.date).format('MMM Do YY')}
                        </strong>{' '}
                      </li>
                      <li className="post-author">
                        {' '}
                        By <Link to="#">{item.author}</Link>{' '}
                      </li>
                    </ul>
                  </div>
                  <div className="dlab-post-title">
                    <h4 className="post-title">
                      <Link to="#">{item.title}</Link>
                    </h4>
                  </div>
                  {
                    <div className="dlab-post-text">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${item?.description.slice(0, 200)}...`,
                        }}
                      />
                    </div>
                  }
                  <div className="dlab-post-readmore">
                    <Link
                      to="/news"
                      title="READ MORE"
                      rel="bookmark"
                      className="site-button btnhover14"
                    >
                      READ MORE
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </Slider>
    </>
  );
}
export default BlogSlider1;
