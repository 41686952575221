import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header12';
import Footer from '../layout/footer4';
import { blogContent6 } from '../element/SliderContent';
import BlogSlider1 from '../element/blogSlider1';
import Testimonial10 from '../element/testimonial10';
import TeamSlider2 from '../element/teamSlider2';
import Gallery4 from '../element/gallery4';
import ScrollToTop from '../element/scrollToTop';

import { getAllAbout, getAllDirectors, getDocuments } from '../server';

// import '../../css/skin/skin-9.min.css';

const imagesarray = [
  {
    id: 1,
    name: 'beer keg',
    title: 'beer keg',
    imgUrl: require('../../images/gallery/beer/pic1.jpg'),
  },
  {
    id: 2,
    name: 'brweing',
    title: 'brweing beer',
    imgUrl: require('../../images/gallery/beer/pic2.jpg'),
  },
  {
    id: 3,
    name: 'bear cup',
    title: 'brweing beer',
    imgUrl: require('../../images/gallery/beer/pic3.jpg'),
  },
  {
    id: 4,
    name: 'beer keg',
    title: 'malt',
    imgUrl: require('../../images/gallery/beer/pic4.jpg'),
  },
];

const bg = require('../../images/background/bg15.jpg');
const bgVideo = require('../../video/video3.mp4');
const certificateIcon = require('../../images/icon/certificate.png');
const certificate1Icon = require('../../images/icon/certificate1.png');
const compliance = require('../../images/icon/pie-chart.png');
const audit = require('../../images/icon/line-segment.png');
const niche = require('../../images/icon/shield.png');
const growth = require('../../images/icon/male-and-female.png');
const sanitizer = require('../../images/icon/like.png');

function Index12() {
  const [aboutUs, setAboutUs] = useState(null);
  const [galleryImage, setGalleryImage] = useState(null);
  const [directors, setDirectors] = useState(null);

  const [images, setImages] = useState([]);

  useEffect(() => {
    getAllDirectors({ type: 'board of directors' })
      .then((res) => {
        console.log('directors', res);
        if (res) {
          setDirectors(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getDocuments({ type: 'photo gallery' })
      .then((res) => {
        console.log('resAboutUs', res);
        if (res) {
          console.log('resAboutUs', res?.data);
          setGalleryImage(res?.data);
          // setTotalPages(Math.ceil(res.data.length / numOfElements));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getAllAbout({ type: 'home_page' })
      .then((res) => {
        console.log('resAboutUs', res);
        if (res) {
          setAboutUs(res?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (galleryImage) {
      const lastIndex = galleryImage.length - 1;
      const slicedGalleryImage = galleryImage.slice(Math.max(lastIndex - 3, 0)); // Take only the last four items
      const images = slicedGalleryImage.map((image) => ({
        id: image.id,
        name: image.title,
        title: image.title,
        imgUrl: `https://rest.alcumusbd.org/abc/${image?.docs[0]?.url}`,
      }));
      setImages(images);
    }
  }, [galleryImage]);

  return (
    <>
      <Header />

      {/* <HomeSlider 
            data={homeSliderContent9}
            show2Btn
            btnText="CONTACT US"
            btn2Text="OUR SERVICES"
            /> */}

      <div className="hero-section relative full-height video-section overlay3 home-slider-12">
        <video src={bgVideo} loop muted autoPlay className="beer-video"></video>

        <div className="hero-content text-center content-center">
          <img src={certificateIcon} alt="" className="beer-icon" />
          <span className="seperator"></span>
          <h1 className="title text-center style4">Alcumus </h1>
          <div className="description ">
            The concept of certification reimagined in a modern way.{' '}
          </div>
          <Link to={'/contact-1'} className="site-button">
            <i className="la la-phone icon"></i>contact us
          </Link>
          <Link to={'/certifications'} className="site-button">
            <i className="la la-file-text icon"></i>our services
          </Link>
        </div>

        {/* <ul className="social-icons">
            <li>
              <Link to={'#'}>
                <i className="fa fa-facebook"></i>
              </Link>
            </li>
            <li>
              <Link to={'#'}>
                <i className="fa fa-twitter"></i>
              </Link>
            </li>
            <li>
              <Link to={'#'}>
                <i className="fa fa-instagram"></i>
              </Link>
            </li>
          </ul> */}
      </div>

      <div className="page-content bg-white">
        <div className="content-block">
          <div className="section-full content-inner bg-white">
            <div className="container">
              <div className="row align-items-center">
                <div
                  className="col-lg-6 col-md-12 m-b30 wow fadeInLeft"
                  data-wow-duration="2s"
                  data-wow-delay="0.3s"
                >
                  <div className="our-story">
                    <span>OUR STORY</span>
                    <h2 className="title">
                      {aboutUs?.title}
                      <br />
                      <span className="text-primary">since 2017</span>
                    </h2>

                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${aboutUs?.body.slice(0, 600)}...`,
                      }}
                    />
                    <Link to="/about-us" className="site-button btnhover20">
                      Read More
                    </Link>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-12 m-b30 our-story-thum wow fadeInRight"
                  data-wow-duration="2s"
                  data-wow-delay="0.3s"
                >
                  <img
                    src={`https://rest.alcumusbd.org/abc/${aboutUs?.image?.url}`}
                    className="radius-sm"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section-full bg-secondry darkBlue content-inner">
            <div className="container">
              <div className="section-head text-center text-white">
                <h2 className="title">Our Services</h2>
                {/* <p>
                    There are many variations of passages of Lorem Ipsum
                    typesetting industry has been the industry's standard dummy
                    text ever since the been when an unknown printer.
                  </p> */}
              </div>
              <div className="section-content row text-black">
                <div
                  className="col-md-6 col-lg-4 col-sm-12 service-box style3 wow fadeInLeft"
                  data-wow-duration="2s"
                  data-wow-delay="0.3s"
                >
                  <div className="icon-bx-wraper" data-name="01">
                    <div className="icon-lg">
                      <Link to="/certification" className="icon-cell">
                        <img
                          src={certificate1Icon}
                          alt=""
                          className="flaticon-boiler"
                        />
                      </Link>
                    </div>
                    <div className="icon-content">
                      <h2 className="dlab-tilte">Certification</h2>
                      <p>
                      Many of the certification services we offer are accredited by the United Kingdom Accreditation Service (UKAS), assessed against internationally recognised
                        </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 col-sm-12 service-box style3 wow fadeInRight"
                  data-wow-duration="2s"
                  data-wow-delay="0.3s"
                >
                  <div className="icon-bx-wraper" data-name="02">
                    <div className="icon-lg">
                      <Link to="#" className="icon-cell">
                        <img src={audit} alt="" className="flaticon-boiler" />
                      </Link>
                    </div>
                    <div className="icon-content">
                      <h2 className="dlab-tilte">2nd Party Auditing</h2>
                      <p>
                      The pressures on supply chains have never been greater. A recent study found that 90% of supply chain managers believe consumer demand is fluctuating more.
                        </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 col-sm-12 service-box style3 wow fadeInDown"
                  data-wow-duration="2s"
                  data-wow-delay="0.3s"
                >
                  <div className="icon-bx-wraper" data-name="03">
                    <div className="icon-lg">
                      <Link to="#" className="icon-cell">
                        <img
                          src={compliance}
                          alt=""
                          className="flaticon-boiler"
                        />
                      </Link>
                    </div>
                    <div className="icon-content">
                      <h2 className="dlab-tilte">Consumer Products Consulting</h2>
                      <p>
                      The end of the day, going forward, a new normal that has evolved from generation X is on the runway heading towards
                        </p>
                    </div>
                  </div>
                </div>
              
                <div
                  className="col-md-6 col-lg-4 col-sm-12 service-box style3 wow fadeInLeft"
                  data-wow-duration="2s"
                  data-wow-delay="0.3s"
                >
                  <div className="icon-bx-wraper" data-name="04">
                    <div className="icon-lg">
                      <Link to="#" className="icon-cell">
                        <img src={niche} alt="" className="flaticon-boiler" />
                      </Link>
                    </div>
                    <div className="icon-content">
                      <h2 className="dlab-tilte">Financial Services Consulting</h2>
                      <p>
                      
Leverage agile frameworks to provide a robust synopsis for high-level overviews. Iterative approaches to corporate strategy foster collaborative thinking.
                        </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 col-sm-12 service-box style3 wow fadeInUp"
                  data-wow-duration="2s"
                  data-wow-delay="0.3s"
                >
                  <div className="icon-bx-wraper" data-name="05">
                    <div className="icon-lg">
                      <Link to="#" className="icon-cell">
                        <img src={growth} alt="" className="flaticon-boiler" />
                      </Link>
                    </div>
                    <div className="icon-content">
                      <h2 className="dlab-tilte">Energy and Enivironment Consulting</h2>
                      <p>
                      Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with
                        </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 col-sm-12 service-box style3 wow fadeInRight"
                  data-wow-duration="2s"
                  data-wow-delay="0.3s"
                >
                  <div className="icon-bx-wraper" data-name="06">
                    <div className="icon-lg">
                      <Link to="#" className="icon-cell">
                        <img
                          src={sanitizer}
                          alt=""
                          className="flaticon-boiler"
                        />
                      </Link>
                    </div>
                    <div className="icon-content">
                      <h2 className="dlab-tilte">Surface Transport & Logistic Consulting</h2>
                      <p>
                      Nanotechnology immersion along the information highway will close the loop on focusing solely on the bottom line
                        </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section-full bg-white content-inner">
            <div className="container">
              <div className="section-head text-center">
                <h2 className="title">Our Gallery</h2>
                <p></p>
              </div>
              {images && images.length > 0 && <Gallery4 images={images} />}
            </div>
          </div>

          <div className="section-full content-inner bg-gray">
            <div className="container">
              <div className="section-head style2 text-center">
                <h2 className="title">Our  Global Partners</h2>
                <p></p>
              </div>

              <TeamSlider2 team={directors} />
            </div>
          </div>

          {/* <div
              className="section-full content-inner overlay-black-middle bg-img-fix"
              style={{ backgroundImage: 'url(' + bg + ')' }}
            >
              <div className="container">
                <div className="section-head style2 text-center text-white">
                  <h2 className="title">Latest blog post</h2>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    typesetting industry has been the industry's standard dummy
                    text ever since the been when an unknown printer.
                  </p>
                </div>

                <BlogSlider1 data={blogContent6} />
              </div>
            </div> */}

          {/* <div className="section-full content-inner-2 bg-gray">
              <div className="container">
                <div className="section-head style2 text-center">
                  <h2 className="title">Our Client</h2>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    typesetting industry has been the industry's standard dummy
                    text ever since the been when an unknown printer.
                  </p>
                </div>

                <Testimonial10 />
              </div>
            </div> */}
        </div>
      </div>
      <Footer />
      <ScrollToTop className="scroltop style2 radius" />
    </>
  );
}

export default Index12;
