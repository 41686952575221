import moment from 'moment';
import React, { Component } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Footer4 from '../layout/footer4';
import Header2 from '../layout/header12';
import { createOrder, cvUpload, fileUpload, getBanners } from '../server';

const bg = require('../../images/banner/bnr29.jpg');

function OrderNow() {
  const {
    register,
    handleSubmit,
    watch,
    control,
    resetField,
    setValue,
    formState: { errors },
  } = useForm();

  const [photo, setPhoto] = useState(null);
  const [cv, setCV] = useState(null);
  const exmilrate = watch('exmilrate');
  const eightquantity500 = watch('eightquantity500');
  const eightquantity420 = watch('eightquantity420');
  const eightquantity400hund = watch('eightquantity400');
  const eigtDwr500 = watch('eightdwrfive');
  const eigtDwr420 = watch('eightdwrfour');
  const eigtDwr400hund = watch('eightdwrfourhund');
  const tenDwr500 = watch('tendwrfive');
  const tenDwr420 = watch('tendwrfour');
  const tenDwr400hund = watch('tendwrfourhund');
  const tenquantity500 = watch('tenquantity500');
  const tenquantity420 = watch('tenquantity420');
  const tenquantity400hund = watch('tenquantity400');
  const twelvedwrfive = watch('twelvedwrfive');
  const twelvedwrfour = watch('twelvedwrfour');
  const twelvedwrfourhund = watch('twelvedwrfourhund');
  const twelvequantity500 = watch('twelvequantity500');
  const twelvequantity420 = watch('twelvequantity420');
  const twelvequantity400hund = watch('twelvequantity400');
  const sixdwrfive = watch('sixdwrfive');
  const sixdwrfour = watch('sixdwrfour');
  const sixdwrfourhund = watch('sixdwrfourhund');
  const sixquantity500 = watch('sixquantity500');
  const sixquantity420 = watch('sixquantity420');
  const sixquantity400hund = watch('sixquantity400');
  const twentydwrfive = watch('twentydwrfive');
  const twentydwrfour = watch('twentydwrfour');
  const twentydwrfourhund = watch('twentydwrfourhund');
  const twentyquantity500 = watch('twentyquantity500');
  const twentyquantity420 = watch('twentyquantity420');
  const twentyquantity400hund = watch('twentyquantity400');

  const twentyfivedwrfive = watch('twentyfivedwrfive');
  const twentyfivedwrfour = watch('twentyfivedwrfour');
  const twentyfivedwrfourhund = watch('twentyfivedwrfourhund');
  const twentyfivequantity500 = watch('twentyfivequantity500');
  const twentyfivequantity420 = watch('twentyfivequantity420');
  const twentyfivequantity400hund = watch('twentyfivequantity400');
  // const total = watch('total');

  const {
    fields: oneBankAmountFields,
    append: oneBankAmountAppend,
    remove: oneBankAmountRemove,
  } = useFieldArray({
    control,
    name: 'amountOneBank',
  });
  const {
    fields: islamiBankAmountFields,
    append: islamiBankAmountAppend,
    remove: islamiBankAmountRemove,
  } = useFieldArray({
    control,
    name: 'amountIslamiBank',
  });

  const {
    fields: alArafahBankAmountFields,
    append: alArafahBankAmountAppend,
    remove: alArafahBankAmountRemove,
  } = useFieldArray({
    control,
    name: 'amountAlArafahBank',
  });

  const {
    fields: pubaliBankAmountFields,
    append: pubaliBankAmountAppend,
    remove: pubaliBankAmountRemove,
  } = useFieldArray({
    control,
    name: 'amountPubaliBank',
  });
  const {
    fields: standardBankAmountFields,
    append: standardBankAmountAppend,
    remove: standardBankAmountRemove,
  } = useFieldArray({
    control,
    name: 'amountStandardBank',
  });
  const {
    fields: jamunaBankAmountFields,
    append: jamunaBankAmountAppend,
    remove: jamunaBankAmountRemove,
  } = useFieldArray({
    control,
    name: 'amountJamunaBank',
  });
  const {
    fields: uttaraBankAmountFields,
    append: uttaraBankAmountAppend,
    remove: uttaraBankAmountRemove,
  } = useFieldArray({
    control,
    name: 'amountUttaraBank',
  });
  const {
    fields: premierBankAmountFields,
    append: premierBankAmountAppend,
    remove: premierBankAmountRemove,
  } = useFieldArray({
    control,
    name: 'amountPremierBank',
  });
  const {
    fields: janataBankAmountFields,
    append: janataBankAmountAppend,
    remove: janataBankAmountRemove,
  } = useFieldArray({
    control,
    name: 'amountJanataBank',
  });

  const {
    fields: agraneeBankAmountFields,
    append: agraneeBankAmountAppend,
    remove: agraneeBankAmountRemove,
  } = useFieldArray({
    control,
    name: 'amountAgraneeBank',
  });

  const {
    fields: sonaliBankAmountFields,
    append: sonaliBankAmountAppend,
    remove: sonaliBankAmountRemove,
  } = useFieldArray({
    control,
    name: 'amountSonaliBank',
  });
  const {
    fields: dhakaBankAmountFields,
    append: dhakaBankAmountAppend,
    remove: dhakaBankAmountRemove,
  } = useFieldArray({
    control,
    name: 'amountDhakaBank',
  });
  const {
    fields: mercantileBankAmountFields,
    append: mercantileBankAmountAppend,
    remove: mercantileBankAmountRemove,
  } = useFieldArray({
    control,
    name: 'amountMercantileBank',
  });
  const {
    fields: rupaliBankAmountFields,
    append: rupaliBankAmountAppend,
    remove: rupaliBankAmountRemove,
  } = useFieldArray({
    control,
    name: 'amountRupaliBank',
  });
  const {
    fields: unitedBankAmountFields,
    append: unitedBankAmountAppend,
    remove: unitedBankAmountRemove,
  } = useFieldArray({
    control,
    name: 'amountUnitedBank',
  });

  const [isCheckedOneBank, setIsCheckedOneBank] = useState(false);

  const [isCheckedIslamiBank, setIsCheckedIslamiBank] = useState(false);

  const [ischeckedalarafaBank, setIsCheckedAlArafahBank] = useState(false);

  const [ischeckedpubali, setIsCheckedPubali] = useState(false);

  const [ischeckedstandard, setIsCheckedStandard] = useState(false);

  const [ischeckedjamuna, setIsCheckedJamuna] = useState(false);

  const [ischeckedUttara, setIsCheckedUttara] = useState(false);

  const [isChekcedPremier, setIsCheckedPremier] = useState(false);

  const [isChekcedJanata, setIsCheckedJanata] = useState(false);

  const [isChekcedAgranee, setIsCheckedAgranee] = useState(false);

  const [isChekcedSonali, setIsCheckedSonali] = useState(false);

  const [isChekcedDhaka, setIsCheckedDhaka] = useState(false);

  const [isChekcedMercantile, setIsCheckedMercantile] = useState(false);

  const [isChekcedRupali, setIsCheckedRupali] = useState(false);

  const [isChekcedUnited, setIsCheckedUnited] = useState(false);

  const handleOnChangeOneBank = (e) => {
    console.log('checkedOnebank', e.target.checked);
    setIsCheckedOneBank(e.target.checked);
  };

  const handleOnChangeIslamiBank = (e) => {
    console.log('handleOnChangeIslamiBank', e.target.checked);
    setIsCheckedIslamiBank(e.target.checked);
  };

  const handleOnChangeAlArafaBank = (e) => {
    console.log('handleOnChangeAlArafaBank', e.target.checked);
    setIsCheckedAlArafahBank(e.target.checked);
  };

  const handleOnChangepubali = (e) => {
    console.log('handleOnChangepubali', e.target.checked);
    setIsCheckedPubali(e.target.checked);
  };

  const handleOnChangestandard = (e) => {
    console.log('handleOnChangestandard', e.target.checked);
    setIsCheckedStandard(e.target.checked);
  };
  const handleOnChangejamuna = (e) => {
    console.log('handleOnChangejamuna', e.target.checked);
    setIsCheckedJamuna(e.target.checked);
  };

  const handleOnChangeUttara = (e) => {
    console.log('handleOnChangeUttara', e.target.checked);
    setIsCheckedUttara(e.target.checked);
  };

  const handleOnChangePremier = (e) => {
    console.log('handleOnChangePremier', e.target.checked);
    setIsCheckedPremier(e.target.checked);
  };

  const handleOnChangeJanata = (e) => {
    console.log('handleOnChangeJanata', e.target.checked);
    setIsCheckedJanata(e.target.checked);
  };
  const handleOnChangeAgranee = (e) => {
    console.log('handleOnChangeAgranee', e.target.checked);
    setIsCheckedAgranee(e.target.checked);
  };
  const handleOnChangeSonali = (e) => {
    console.log('handleOnChangeSonali', e.target.checked);
    setIsCheckedSonali(e.target.checked);
  };
  const handleOnChangeDhaka = (e) => {
    console.log('handleOnChangeDhaka', e.target.checked);
    setIsCheckedDhaka(e.target.checked);
  };
  const handleOnChangeMercantile = (e) => {
    console.log('handleOnChangeMercantile', e.target.checked);
    setIsCheckedMercantile(e.target.checked);
  };
  const handleOnChangeRupali = (e) => {
    console.log('handleOnChangeRupali', e.target.checked);
    setIsCheckedRupali(e.target.checked);
  };

  const handleOnChangeUnited = (e) => {
    console.log('handleOnChangeUnited', e.target.checked);
    setIsCheckedUnited(e.target.checked);
  };

  const eightPrice500 = watch(['eightprice500']);
  const eightPrice420 = watch(['eightprice420']);
  const eightPrice400 = watch(['eightprice400']);
  const tenPrice500 = watch(['tenprice500']);
  const tenPrice420 = watch(['tenprice420']);
  const tenPrice400 = watch(['tenprice400']);
  const twelvePrice500 = watch(['twelveprice500']);
  const twelvePrice420 = watch(['twelveprice420']);
  const twelvePrice400 = watch(['twelveprice400']);
  const sixPrice500 = watch(['sixprice500']);
  const sixPrice420 = watch(['sixprice420']);
  const sixPrice400 = watch(['sixprice400']);
  const twentyPrice500 = watch(['twentyprice500']);
  const twentyPrice420 = watch(['twentyprice420']);
  const twentyPrice400 = watch(['twentyprice400']);
  const twentyFivePrice500 = watch(['twentyfiveprice500']);
  const twentyFivePrice420 = watch(['twentyfiveprice420']);
  const twentyFivePrice400 = watch(['twentyfiveprice400']);

  const fare = watch(['fare']);
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    getBanners({ type: 'career', subType: 'create online CV' })
      .then((res) => {
        console.log('resAboutUs', res);
        if (res) {
          setBanner(res?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [definition, setDefinition] = useState(false);

  const submitFrom = async (data) => {
    console.log('data', data);
    const current = new Date();
    const currentHour = current.getHours();
    var dwrDetails = [
      {
        title: '8 MM',
        dwr: `${data?.eightdwrfive}`,
        price: data.eightprice500,
        quantity: data.eightquantity500,
      },
      {
        title: '8 MM',
        dwr: `${data?.eightdwrfour}`,
        price: data.eightprice420,
        quantity: data.eightquantity420,
      },
      {
        title: '8 MM',
        dwr: `${data?.eightdwrfourhund}`,
        price: data.eightprice400,
        quantity: data.eightquantity400,
      },
      {
        title: '10 MM',
        dwr: `${data?.tendwrfive}`,

        price: data.tenprice500,
        quantity: data.tenquantity500,
      },
      {
        title: '10 MM',
        dwr: `${data?.tendwrfour}`,
        price: data.tenprice420,
        quantity: data.tenquantity420,
      },
      {
        title: '10 MM',
        dwr: `${data?.tendwrfourhund}`,
        price: data.tenprice400,
        quantity: data.tenquantity400,
      },
      {
        title: '12 MM',
        dwr: `${data?.twelvedwrfive}`,
        price: data.twelveprice500,
        quantity: data.twelvequantity500,
      },
      {
        title: '12 MM',
        dwr: `${data?.twelvedwrfour}`,
        price: data.twelveprice420,
        quantity: data.twelvequantity420,
      },
      {
        title: '12 MM',
        dwr: `${data?.twelvedwrfourhund}`,
        price: data.twelveprice400,
        quantity: data.twelvequantity400,
      },
      {
        title: '16 MM',
        dwr: `${data?.sixdwrfive}`,
        price: data.sixprice500,
        quantity: data.sixquantity500,
      },
      {
        title: '16 MM',
        dwr: `${data?.sixdwrfour}`,
        price: data.sixprice420,
        quantity: data.sixquantity420,
      },
      {
        title: '16 MM',
        dwr: `${data?.sixdwrfourhund}`,
        price: data.sixprice400,
        quantity: data.sixquantity400,
      },
      {
        title: '20 MM',
        dwr: `${data?.twentydwrfive}`,
        price: data.twentyprice500,
        quantity: data.twentyquantity500,
      },
      {
        title: '20 MM',
        dwr: `${data?.twentydwrfour}`,
        price: data.twentyprice420,
        quantity: data.twentyquantity420,
      },
      {
        title: '20 MM',
        dwr: `${data?.twentydwrfourhund}`,
        price: data.twentyprice400,
        quantity: data.twentyquantity400,
      },
      {
        title: '25 MM',
        dwr: `${data?.twentyfivedwrfive}`,
        price: data.twentyfiveprice500,
        quantity: data.twentyfivequantity500,
      },
      {
        title: '25 MM',
        dwr: `${data?.twentyfivedwrfour}`,
        price: data.twentyfiveprice420,
        quantity: data.twentyfivequantity420,
      },
      {
        title: '25 MM',
        dwr: `${data?.twentyfivedwrfourhund}`,
        price: data.twentyfiveprice400,
        quantity: data.twentyfivequantity400,
      },
    ];

    console.log('dwrDetails', dwrDetails);

    const dwrfilter = dwrDetails.filter((item) => item.dwr !== 'false');
    console.log('dwrfilter', dwrfilter);
    const hasUndefinedPrice = dwrfilter.some(
      (item) => item.price === undefined
    );

    if (hasUndefinedPrice == false) {
      var bankDetails = [
        {
          account: isCheckedOneBank ? 'One Bank' : undefined,
          amount: data?.amountOneBank.map((item) => item.amount).join(','),
        },
        {
          account: isCheckedIslamiBank ? 'Islami Bank' : undefined,
          amount: data?.amountIslamiBank.map((item) => item.amount).join(','),
        },
        {
          account: ischeckedalarafaBank ? 'Al Arafah Bank' : undefined,
          amount: data?.amountAlArafahBank.map((item) => item.amount).join(','),
        },
        {
          account: ischeckedpubali ? 'Pubali Bank' : undefined,
          amount: data?.amountPubaliBank.map((item) => item.amount).join(','),
        },
        {
          account: ischeckedstandard ? 'Standard Bank' : undefined,
          amount: data?.amountStandardBank.map((item) => item.amount).join(','),
        },
        {
          account: ischeckedjamuna ? 'Jamuna Bank' : undefined,
          amount: data?.amountJamunaBank.map((item) => item.amount).join(','),
        },
        {
          account: ischeckedUttara ? 'Uttara Bank' : undefined,
          amount: data?.amountUttaraBank.map((item) => item.amount).join(','),
        },
        {
          account: isChekcedPremier ? 'Premier Bank' : undefined,
          amount: data?.amountPremierBank.map((item) => item.amount).join(','),
        },
        {
          account: isChekcedJanata ? 'Janata Bank' : undefined,
          amount: data?.amountJanataBank.map((item) => item.amount).join(','),
        },
        {
          account: isChekcedAgranee ? 'Agranee Bank' : undefined,
          amount: data?.amountAgraneeBank.map((item) => item.amount).join(','),
        },
        {
          account: isChekcedSonali ? 'Sonali Bank' : undefined,
          amount: data?.amountSonaliBank.map((item) => item.amount).join(','),
        },
        {
          account: isChekcedDhaka ? 'Dhaka Bank' : undefined,
          amount: data?.amountDhakaBank.map((item) => item.amount).join(','),
        },
        {
          account: isChekcedMercantile ? 'Mercantile Bank' : undefined,
          amount: data?.amountMercantileBank
            .map((item) => item.amount)
            .join(','),
        },
        {
          account: isChekcedRupali ? 'Rupali Bank' : undefined,
          amount: data?.amountRupaliBank.map((item) => item.amount).join(','),
        },
        {
          account: isChekcedUnited ? 'United Bank' : undefined,
          amount: data?.amountUnitedBank.map((item) => item.amount).join(','),
        },
      ];
      const bankDetailsFilter = bankDetails.filter(
        (item) => item.account !== undefined
      );

      const submittingdata = {
        accountName: data.acName,
        address: data.address,
        deliveryInformation: data.deliveryInfo,
        dwrs: dwrfilter,

        total: data?.total.toString(),
        exMillRate: data?.exmilrate,
        loadingAndTruckFare: data?.fare,

        banks: bankDetailsFilter,
        status: 'requested',
        reference: data?.reference,
        createdById: Number(localStorage.getItem('userId')),
        orderDate: current,
        month: current.getMonth() + 1,
        year: current.getFullYear(),
        paymentAfterDelivery:
          data?.paymentAfterDelivery === 'true' ? 'true' : 'false',
        credit: data?.credit === 'true' ? 'true' : 'false',
      };
      console.log('submittingdata', submittingdata);
      if (submittingdata && dwrfilter.length > 0) {
        if (currentHour >= 8 && currentHour < 16) {
          createOrder(submittingdata)
            .then((res) => {
              console.log('res', res);
              if (res) {
                Swal.fire({
                  title: 'Success!',
                  text: 'Order Created Successfully',
                  icon: 'success',
                  confirmButtonText: 'Ok',
                }).then(() => {
                  window.location.href = '/order-list';
                });
              }
            })
            .catch((err) => {
              console.log('err', err);
            });
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'Order can be placed between 8 AM to 4 PM',
            icon: 'error',
            confirmButtonText: 'Ok',
          });
        }
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Please Select At least One DWR',
          icon: 'error',
          confirmButtonText: 'Ok',
        });
      }
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Please fill all the DWRs Quantity',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
    }
  };

  const preventNegativeValues = (e) =>
    ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

  useEffect(() => {
    setValue(
      'total',
      Number(eightPrice500[0] && eigtDwr500 ? eightPrice500[0] : 0) +
        Number(eightPrice420[0] && eigtDwr420 ? eightPrice420[0] : 0) +
        Number(eightPrice400[0] && eigtDwr400hund ? eightPrice400[0] : 0) +
        Number(tenPrice500[0] && tenDwr500 ? tenPrice500[0] : 0) +
        Number(tenPrice420[0] && tenDwr420 ? tenPrice420[0] : 0) +
        Number(tenPrice400[0] && tenDwr400hund ? tenPrice400[0] : 0) +
        Number(twelvePrice500[0] && twelvedwrfive ? twelvePrice500[0] : 0) +
        Number(twelvePrice420[0] && twelvedwrfour ? twelvePrice420[0] : 0) +
        Number(twelvePrice400[0] && twelvedwrfourhund ? twelvePrice400[0] : 0) +
        Number(sixPrice500[0] && sixdwrfive ? sixPrice500[0] : 0) +
        Number(sixPrice420[0] && sixdwrfour ? sixPrice420[0] : 0) +
        Number(sixPrice400[0] && sixdwrfourhund ? sixPrice400[0] : 0) +
        Number(twentyPrice500[0] && twentydwrfive ? twentyPrice500[0] : 0) +
        Number(twentyPrice420[0] && twentydwrfour ? twentyPrice420[0] : 0) +
        Number(twentyPrice400[0] && twentydwrfourhund ? twentyPrice400[0] : 0) +
        Number(
          twentyFivePrice500[0] && twentyfivedwrfive ? twentyFivePrice500[0] : 0
        ) +
        Number(
          twentyFivePrice420[0] && twentyfivedwrfour ? twentyFivePrice420[0] : 0
        ) +
        Number(
          twentyFivePrice400[0] && twentyfivedwrfourhund
            ? twentyFivePrice400[0]
            : 0
        ) +
        Number(fare[0])
    );
  }, [
    eightPrice500,

    eightPrice420,
    eightPrice400,
    tenPrice500,
    tenPrice420,
    tenPrice400,
    twelvePrice500,
    twelvePrice420,
    twelvePrice400,
    sixPrice500,
    sixPrice420,
    sixPrice400,
    twentyPrice500,
    twentyPrice420,
    twentyPrice400,
    twentyFivePrice500,
    twentyFivePrice420,
    twentyFivePrice400,

    eigtDwr500,
    eigtDwr420,
    eigtDwr400hund,
    tenDwr500,
    tenDwr420,
    tenDwr400hund,
    twelvedwrfive,
    twelvedwrfour,
    twelvedwrfourhund,
    sixdwrfive,
    sixdwrfour,
    sixdwrfourhund,
    twentydwrfive,
    twentydwrfour,
    twentydwrfourhund,
    twentyfivedwrfive,
    twentyfivedwrfour,
    twentyfivedwrfourhund,

    fare,
    setValue,
  ]);

  useEffect(() => {
    if (eightquantity500 && eightquantity500 > 0) {
      setValue(
        'eightprice500',
        exmilrate * eightquantity500 + 1000 * eightquantity500
      );
    }
    if (eightquantity420 && eightquantity420 > 0) {
      setValue(
        'eightprice420',
        exmilrate * eightquantity420 + 1000 * eightquantity420
      );
    }
    if (eightquantity400hund && eightquantity400hund > 0) {
      setValue(
        'eightprice400',
        exmilrate * eightquantity400hund + 1000 * eightquantity400hund
      );
    }
    if (tenquantity500 && tenquantity500 > 0) {
      setValue('tenprice500', exmilrate * tenquantity500);
    }
    if (tenquantity420 && tenquantity420 > 0) {
      setValue('tenprice420', exmilrate * tenquantity420);
    }
    if (tenquantity400hund && tenquantity400hund > 0) {
      setValue('tenprice400', exmilrate * tenquantity400hund);
    }
    if (twelvequantity500 && twelvequantity500 > 0) {
      setValue('twelveprice500', exmilrate * twelvequantity500);
    }
    if (twelvequantity420 && twelvequantity420 > 0) {
      setValue('twelveprice420', exmilrate * twelvequantity420);
    }
    if (twelvequantity400hund && twelvequantity400hund > 0) {
      setValue('twelveprice400', exmilrate * twelvequantity400hund);
    }
    if (sixquantity500 && sixquantity500 > 0) {
      setValue('sixprice500', exmilrate * sixquantity500);
    }
    if (sixquantity420 && sixquantity420 > 0) {
      setValue('sixprice420', exmilrate * sixquantity420);
    }
    if (sixquantity400hund && sixquantity400hund > 0) {
      setValue('sixprice400', exmilrate * sixquantity400hund);
    }
    if (twentyquantity500 && twentyquantity500 > 0) {
      setValue('twentyprice500', exmilrate * twentyquantity500);
    }
    if (twentyquantity420 && twentyquantity420 > 0) {
      setValue('twentyprice420', exmilrate * twentyquantity420);
    }
    if (twentyquantity400hund && twentyquantity400hund > 0) {
      setValue('twentyprice400', exmilrate * twentyquantity400hund);
    }
    if (twentyfivequantity500 && twentyfivequantity500 > 0) {
      setValue('twentyfiveprice500', exmilrate * twentyfivequantity500);
    }
    if (twentyfivequantity420 && twentyfivequantity420 > 0) {
      setValue('twentyfiveprice420', exmilrate * twentyfivequantity420);
    }
    if (twentyfivequantity400hund && twentyfivequantity400hund > 0) {
      setValue('twentyfiveprice400', exmilrate * twentyfivequantity400hund);
    }
  }, [
    eightquantity500,
    eightquantity400hund,
    eightquantity420,
    tenquantity500,
    tenquantity420,
    tenquantity400hund,
    twelvequantity400hund,
    twelvequantity420,
    twelvequantity500,
    sixquantity400hund,
    sixquantity420,
    sixquantity500,
    twentyquantity400hund,
    twentyquantity420,
    twentyquantity500,
    twentyfivequantity400hund,
    twentyfivequantity420,
    twentyfivequantity500,
    setValue,
    exmilrate,
  ]);

  return (
    <>
      {banner && (
        <div className="page-content bg-white">
          <div
            className="dlab-bnr-inr overlay-black-middle bg-pt"
            style={{
              backgroundImage: `url(' https://rest.alcumusbd.org/abc/${banner.image.url}')`,
            }}
          >
            <div className="container">
              <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">Order Now</h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li>Order Now </li>

                    <Link to="/order-list">
                      <li>Order List {'>'} </li>
                    </Link>
                    <Link to="/">
                      <li>Home{'>'} </li>
                    </Link>
                    <Link
                      onClick={() => {
                        localStorage.removeItem('userId');
                        localStorage.removeItem('access_token');
                        window.location.href = '/login-page';
                      }}
                    >
                      <li>Log Out</li>
                    </Link>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="section-full content-inner shop-account">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <h2 className="font-weight-700 m-t0 m-b40">
                    CREATE AN ORDER
                  </h2>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 m-b30">
                  <div className="p-a30 border-1  m-auto">
                    <div className="tab-content">
                      <form
                        id="login"
                        className="tab-pane active"
                        onSubmit={handleSubmit(submitFrom)}
                      >
                        <h4 className="font-weight-700">ORDER INFORMATION</h4>
                        <h6>{`( Order can be place between 8 AM to 4 PM)`}</h6>

                        <div className="form-group">
                          <label className="font-weight-700">A/C Name *</label>
                          <input
                            required
                            className="form-control"
                            placeholder="Full Name"
                            type="text"
                            {...register('acName')}
                          />
                        </div>

                        <div className="form-group">
                          <label className="font-weight-700">Address*</label>
                          <input
                            type="text"
                            required
                            className="form-control"
                            placeholder="Address"
                            {...register('address')}
                          />
                        </div>
                        <div className="form-group">
                          <label className="font-weight-700">
                            Delivery Information*
                          </label>
                          <input
                            type="text"
                            required
                            className="form-control"
                            placeholder="Delivery Information"
                            {...register('deliveryInfo')}
                          />
                        </div>
                        <div className="form-group">
                          <label className="font-weight-700">
                            Ex-Mill Rate (Per Ton)*
                          </label>
                          <input
                            type="number"
                            required
                            className="form-control"
                            placeholder="Ex-Mill Rate"
                            {...register('exmilrate')}
                            onWheel={(e) => e.target.blur()}
                          />
                        </div>
                        {exmilrate > 0 && (
                          <>
                            <div row form-group>
                              <div className="row">
                                <label
                                  className="font-weight-700"
                                  style={{ paddingLeft: '18px' }}
                                >
                                  DWR 8 MM*
                                </label>
                                <div>
                                  <div class="form-check form-check-inline">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      id="inlineCheckbox1"
                                      // checked={isChecked}
                                      // onClick={(e) => handleOnChange(e)}
                                      value="500"
                                      {...register('eightdwrfive')}
                                    />
                                    <label
                                      class="form-check-label"
                                      for="inlineCheckbox1"
                                    >
                                      500
                                    </label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      id="inlineCheckbox2"
                                      // checked={ischeckedfour}
                                      // onClick={(e) => handleOnChangeFour(e)}
                                      value="420"
                                      {...register('eightdwrfour')}
                                    />
                                    <label
                                      class="form-check-label"
                                      for="inlineCheckbox2"
                                    >
                                      420
                                    </label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      id="inlineCheckbox2hundred"
                                      // checked={ischeckedfour}
                                      // onClick={(e) => handleOnChangeFour(e)}
                                      value="400"
                                      {...register('eightdwrfourhund')}
                                    />
                                    <label
                                      class="form-check-label"
                                      for="inlineCheckbox2hundred"
                                    >
                                      400
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {eigtDwr500 && eigtDwr500 > 0 && (
                              <div className="row form-group">
                                <div className="col">
                                  <label className="font-weight-700">
                                    8 MM (500)*
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onKeyDown={preventNegativeValues}
                                    placeholder="Quantity"
                                    {...register('eightquantity500')}
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </div>
                                {eightquantity500 && eightquantity500 > 0 && (
                                  <div
                                    className="col"
                                    style={{ paddingTop: '10px' }}
                                  >
                                    <label className="font-weight-700"></label>
                                    <input
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      required
                                      disabled
                                      className="form-control"
                                      onKeyDown={preventNegativeValues}
                                      placeholder="Price"
                                      {...register('eightprice500')}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                            {eigtDwr420 && eigtDwr420 > 0 && (
                              <div className="row form-group">
                                <div className="col">
                                  <label className="font-weight-700">
                                    8 MM (420)*
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onKeyDown={preventNegativeValues}
                                    placeholder="Quantity"
                                    {...register('eightquantity420')}
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </div>
                                {eightquantity420 && eightquantity420 > 0 && (
                                  <div
                                    className="col"
                                    style={{ paddingTop: '10px' }}
                                  >
                                    <label className="font-weight-700"></label>
                                    <input
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      required
                                      disabled
                                      className="form-control"
                                      onKeyDown={preventNegativeValues}
                                      placeholder="Price"
                                      {...register('eightprice420')}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                            {eigtDwr400hund && eigtDwr400hund > 0 && (
                              <div className="row form-group">
                                <div className="col">
                                  <label className="font-weight-700">
                                    8 MM (400)*
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onKeyDown={preventNegativeValues}
                                    placeholder="Quantity"
                                    {...register('eightquantity400')}
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </div>
                                {eightquantity400hund &&
                                  eightquantity400hund > 0 && (
                                    <div
                                      className="col"
                                      style={{ paddingTop: '10px' }}
                                    >
                                      <label className="font-weight-700"></label>
                                      <input
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        required
                                        disabled
                                        className="form-control"
                                        onKeyDown={preventNegativeValues}
                                        placeholder="Price"
                                        {...register('eightprice400')}
                                      />
                                    </div>
                                  )}
                              </div>
                            )}

                            <div row form-group>
                              <div className="row">
                                <label
                                  className="font-weight-700"
                                  style={{ paddingLeft: '18px' }}
                                >
                                  DWR 10MM*
                                </label>
                                <div>
                                  <div class="form-check form-check-inline">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      id="inlineCheckbox3"
                                      value="500"
                                      {...register('tendwrfive')}
                                    />
                                    <label
                                      class="form-check-label"
                                      for="inlineCheckbox3"
                                    >
                                      500
                                    </label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      id="inlineCheckbox4"
                                      value="420"
                                      {...register('tendwrfour')}
                                    />
                                    <label
                                      class="form-check-label"
                                      for="inlineCheckbox4"
                                    >
                                      420
                                    </label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      id="inlineCheckbox4hundred"
                                      value="400"
                                      {...register('tendwrfourhund')}
                                    />
                                    <label
                                      class="form-check-label"
                                      for="inlineCheckbox4hundred"
                                    >
                                      400
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {tenDwr500 && tenDwr500 > 0 && (
                              <div className="row form-group">
                                <div className="col">
                                  <label className="font-weight-700">
                                    10 MM (500)*
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onKeyDown={preventNegativeValues}
                                    placeholder="Quantity"
                                    {...register('tenquantity500')}
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </div>
                                {tenquantity500 && tenquantity500 > 0 && (
                                  <div
                                    className="col"
                                    style={{ paddingTop: '10px' }}
                                  >
                                    <label className="font-weight-700"></label>
                                    <input
                                      type="number"
                                      required
                                      disabled
                                      className="form-control"
                                      onKeyDown={preventNegativeValues}
                                      placeholder="Price"
                                      {...register('tenprice500')}
                                      onWheel={(e) => e.target.blur()}
                                    />
                                  </div>
                                )}
                              </div>
                            )}

                            {tenDwr420 && tenDwr420 > 0 && (
                              <div className="row form-group">
                                <div className="col">
                                  <label className="font-weight-700">
                                    10 MM (420)*
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onKeyDown={preventNegativeValues}
                                    placeholder="Quantity"
                                    {...register('tenquantity420')}
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </div>
                                {tenquantity420 && tenquantity420 > 0 && (
                                  <div
                                    className="col"
                                    style={{ paddingTop: '10px' }}
                                  >
                                    <label className="font-weight-700"></label>
                                    <input
                                      type="number"
                                      required
                                      disabled
                                      className="form-control"
                                      onKeyDown={preventNegativeValues}
                                      placeholder="Price"
                                      {...register('tenprice420')}
                                      onWheel={(e) => e.target.blur()}
                                    />
                                  </div>
                                )}
                              </div>
                            )}

                            {tenDwr400hund && tenDwr400hund > 0 && (
                              <div className="row form-group">
                                <div className="col">
                                  <label className="font-weight-700">
                                    10 MM (400)*
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onKeyDown={preventNegativeValues}
                                    placeholder="Quantity"
                                    {...register('tenquantity400')}
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </div>
                                {tenquantity400hund &&
                                  tenquantity400hund > 0 && (
                                    <div
                                      className="col"
                                      style={{ paddingTop: '10px' }}
                                    >
                                      <label className="font-weight-700"></label>
                                      <input
                                        type="number"
                                        required
                                        disabled
                                        className="form-control"
                                        onKeyDown={preventNegativeValues}
                                        placeholder="Price"
                                        {...register('tenprice400')}
                                        onWheel={(e) => e.target.blur()}
                                      />
                                    </div>
                                  )}
                              </div>
                            )}

                            <div row form-group>
                              <div className="row">
                                <label
                                  className="font-weight-700"
                                  style={{ paddingLeft: '18px' }}
                                >
                                  DWR 12MM*
                                </label>
                                <div>
                                  <div class="form-check form-check-inline">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      id="inlineCheckbox5"
                                      value="500"
                                      {...register('twelvedwrfive')}
                                    />
                                    <label
                                      class="form-check-label"
                                      for="inlineCheckbox5"
                                    >
                                      500
                                    </label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      id="inlineCheckbox6"
                                      value="420"
                                      {...register('twelvedwrfour')}
                                    />
                                    <label
                                      class="form-check-label"
                                      for="inlineCheckbox6"
                                    >
                                      420
                                    </label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      id="inlineCheckbox6hundred"
                                      value="400"
                                      {...register('twelvedwrfourhund')}
                                    />
                                    <label
                                      class="form-check-label"
                                      for="inlineCheckbox6hundred"
                                    >
                                      400
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {twelvedwrfive && twelvedwrfive > 0 && (
                              <div className="row form-group">
                                <div className="col">
                                  <label className="font-weight-700">
                                    12 MM (500)*
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onKeyDown={preventNegativeValues}
                                    placeholder="Quantity"
                                    {...register('twelvequantity500')}
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </div>
                                {twelvequantity500 && twelvequantity500 > 0 && (
                                  <div
                                    className="col"
                                    style={{ paddingTop: '10px' }}
                                  >
                                    <label className="font-weight-700"></label>
                                    <input
                                      type="number"
                                      required
                                      disabled
                                      className="form-control"
                                      onKeyDown={preventNegativeValues}
                                      placeholder="Price"
                                      {...register('twelveprice500')}
                                      onWheel={(e) => e.target.blur()}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                            {twelvedwrfour && twelvedwrfour > 0 && (
                              <div className="row form-group">
                                <div className="col">
                                  <label className="font-weight-700">
                                    12 MM (420)*
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onKeyDown={preventNegativeValues}
                                    placeholder="Quantity"
                                    {...register('twelvequantity420')}
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </div>
                                {twelvequantity420 && twelvequantity420 > 0 && (
                                  <div
                                    className="col"
                                    style={{ paddingTop: '10px' }}
                                  >
                                    <label className="font-weight-700"></label>
                                    <input
                                      type="number"
                                      required
                                      disabled
                                      className="form-control"
                                      onKeyDown={preventNegativeValues}
                                      placeholder="Price"
                                      {...register('twelveprice420')}
                                      onWheel={(e) => e.target.blur()}
                                    />
                                  </div>
                                )}
                              </div>
                            )}

                            {twelvedwrfourhund && twelvedwrfourhund > 0 && (
                              <div className="row form-group">
                                <div className="col">
                                  <label className="font-weight-700">
                                    12 MM (400)*
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onKeyDown={preventNegativeValues}
                                    placeholder="Quantity"
                                    {...register('twelvequantity400')}
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </div>
                                {twelvequantity400hund &&
                                  twelvequantity400hund > 0 && (
                                    <div
                                      className="col"
                                      style={{ paddingTop: '10px' }}
                                    >
                                      <label className="font-weight-700"></label>
                                      <input
                                        type="number"
                                        required
                                        disabled
                                        className="form-control"
                                        onKeyDown={preventNegativeValues}
                                        placeholder="Price"
                                        {...register('twelveprice400')}
                                        onWheel={(e) => e.target.blur()}
                                      />
                                    </div>
                                  )}
                              </div>
                            )}

                            <div row form-group>
                              <div className="row">
                                <label
                                  className="font-weight-700"
                                  style={{ paddingLeft: '18px' }}
                                >
                                  DWR 16MM*
                                </label>
                                <div>
                                  <div class="form-check form-check-inline">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      id="inlineCheckbox7"
                                      value="500"
                                      {...register('sixdwrfive')}
                                    />
                                    <label
                                      class="form-check-label"
                                      for="inlineCheckbox7"
                                    >
                                      500
                                    </label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      id="inlineCheckbox8"
                                      value="420"
                                      {...register('sixdwrfour')}
                                    />
                                    <label
                                      class="form-check-label"
                                      for="inlineCheckbox8"
                                    >
                                      420
                                    </label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      id="inlineCheckbox8hundred"
                                      value="400"
                                      {...register('sixdwrfourhund')}
                                    />
                                    <label
                                      class="form-check-label"
                                      for="inlineCheckbox8hundred"
                                    >
                                      400
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {sixdwrfive && sixdwrfive > 0 && (
                              <div className="row form-group">
                                <div className="col">
                                  <label className="font-weight-700">
                                    16 MM (500)*
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onKeyDown={preventNegativeValues}
                                    placeholder="Quantity"
                                    {...register('sixquantity500')}
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </div>
                                {sixquantity500 && sixquantity500 > 0 && (
                                  <div
                                    className="col"
                                    style={{ paddingTop: '10px' }}
                                  >
                                    <label className="font-weight-700"></label>
                                    <input
                                      type="number"
                                      required
                                      disabled
                                      className="form-control"
                                      onKeyDown={preventNegativeValues}
                                      placeholder="Price"
                                      {...register('sixprice500')}
                                      onWheel={(e) => e.target.blur()}
                                    />
                                  </div>
                                )}
                              </div>
                            )}

                            {sixdwrfour && sixdwrfour > 0 && (
                              <div className="row form-group">
                                <div className="col">
                                  <label className="font-weight-700">
                                    16 MM (420)*
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onKeyDown={preventNegativeValues}
                                    placeholder="Quantity"
                                    {...register('sixquantity420')}
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </div>
                                {sixquantity420 && sixquantity420 > 0 && (
                                  <div
                                    className="col"
                                    style={{ paddingTop: '10px' }}
                                  >
                                    <label className="font-weight-700"></label>
                                    <input
                                      type="number"
                                      required
                                      disabled
                                      className="form-control"
                                      onKeyDown={preventNegativeValues}
                                      placeholder="Price"
                                      {...register('sixprice420')}
                                      onWheel={(e) => e.target.blur()}
                                    />
                                  </div>
                                )}
                              </div>
                            )}

                            {sixdwrfourhund && sixdwrfourhund > 0 && (
                              <div className="row form-group">
                                <div className="col">
                                  <label className="font-weight-700">
                                    16 MM (400)*
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onKeyDown={preventNegativeValues}
                                    placeholder="Quantity"
                                    {...register('sixquantity400')}
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </div>
                                {sixquantity400hund &&
                                  sixquantity400hund > 0 && (
                                    <div
                                      className="col"
                                      style={{ paddingTop: '10px' }}
                                    >
                                      <label className="font-weight-700"></label>
                                      <input
                                        type="number"
                                        required
                                        disabled
                                        className="form-control"
                                        onKeyDown={preventNegativeValues}
                                        placeholder="Price"
                                        {...register('sixprice400')}
                                        onWheel={(e) => e.target.blur()}
                                      />
                                    </div>
                                  )}
                              </div>
                            )}

                            <div>
                              <div className="row">
                                <label
                                  className="font-weight-700"
                                  style={{ paddingLeft: '18px' }}
                                >
                                  DWR 20MM*
                                </label>
                                <div>
                                  <div class="form-check form-check-inline">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      id="inlineCheckbox9"
                                      value="500"
                                      {...register('twentydwrfive')}
                                    />
                                    <label
                                      class="form-check-label"
                                      for="inlineCheckbox9"
                                    >
                                      500
                                    </label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      id="inlineCheckbox10"
                                      value="420"
                                      {...register('twentydwrfour')}
                                    />
                                    <label
                                      class="form-check-label"
                                      for="inlineCheckbox10"
                                    >
                                      420
                                    </label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      id="inlineCheckbox10hundred"
                                      value="400"
                                      {...register('twentydwrfourhund')}
                                    />
                                    <label
                                      class="form-check-label"
                                      for="inlineCheckbox10hundred"
                                    >
                                      400
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {twentydwrfive && twentydwrfive > 0 && (
                              <div className="row form-group">
                                <div className="col">
                                  <label className="font-weight-700">
                                    20 MM (500)*
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onKeyDown={preventNegativeValues}
                                    placeholder="Quantity"
                                    {...register('twentyquantity500')}
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </div>
                                {twentyquantity500 && twentyquantity500 > 0 && (
                                  <div
                                    className="col"
                                    style={{ paddingTop: '10px' }}
                                  >
                                    <label className="font-weight-700"></label>
                                    <input
                                      type="number"
                                      required
                                      disabled
                                      className="form-control"
                                      onKeyDown={preventNegativeValues}
                                      placeholder="Price"
                                      {...register('twentyprice500')}
                                      onWheel={(e) => e.target.blur()}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                            {twentydwrfour && twentydwrfour > 0 && (
                              <div className="row form-group">
                                <div className="col">
                                  <label className="font-weight-700">
                                    20 MM (420)*
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onKeyDown={preventNegativeValues}
                                    placeholder="Quantity"
                                    {...register('twentyquantity420')}
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </div>
                                {twentyquantity420 && twentyquantity420 > 0 && (
                                  <div
                                    className="col"
                                    style={{ paddingTop: '10px' }}
                                  >
                                    <label className="font-weight-700"></label>
                                    <input
                                      type="number"
                                      required
                                      disabled
                                      className="form-control"
                                      onKeyDown={preventNegativeValues}
                                      placeholder="Price"
                                      {...register('twentyprice420')}
                                      onWheel={(e) => e.target.blur()}
                                    />
                                  </div>
                                )}
                              </div>
                            )}

                            {twentydwrfourhund && twentydwrfourhund > 0 && (
                              <div className="row form-group">
                                <div className="col">
                                  <label className="font-weight-700">
                                    20 MM (400)*
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onKeyDown={preventNegativeValues}
                                    placeholder="Quantity"
                                    {...register('twentyquantity400')}
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </div>
                                {twentyquantity400hund &&
                                  twentyquantity400hund > 0 && (
                                    <div
                                      className="col"
                                      style={{ paddingTop: '10px' }}
                                    >
                                      <label className="font-weight-700"></label>
                                      <input
                                        type="number"
                                        required
                                        disabled
                                        className="form-control"
                                        onKeyDown={preventNegativeValues}
                                        placeholder="Price"
                                        {...register('twentyprice400')}
                                        onWheel={(e) => e.target.blur()}
                                      />
                                    </div>
                                  )}
                              </div>
                            )}

                            <div>
                              <div className="row">
                                <label
                                  className="font-weight-700"
                                  style={{ paddingLeft: '18px' }}
                                >
                                  DWR 25MM*
                                </label>
                                <div>
                                  <div class="form-check form-check-inline">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      id="inlineCheckbox11"
                                      value="500"
                                      {...register('twentyfivedwrfive')}
                                    />
                                    <label
                                      class="form-check-label"
                                      for="inlineCheckbox11"
                                    >
                                      500
                                    </label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      id="inlineCheckbox12"
                                      value="420"
                                      {...register('twentyfivedwrfour')}
                                    />
                                    <label
                                      class="form-check-label"
                                      for="inlineCheckbox12"
                                    >
                                      420
                                    </label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      id="inlineCheckbox12hundred"
                                      value="400"
                                      {...register('twentyfivedwrfourhund')}
                                    />
                                    <label
                                      class="form-check-label"
                                      for="inlineCheckbox12hundred"
                                    >
                                      400
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {twentyfivedwrfive && twentyfivedwrfive > 0 && (
                              <div className="row form-group">
                                <div className="col">
                                  <label className="font-weight-700">
                                    25 MM(500)*
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onKeyDown={preventNegativeValues}
                                    placeholder="Quantity"
                                    {...register('twentyfivequantity500')}
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </div>
                                {twentyfivequantity500 &&
                                  twentyfivequantity500 > 0 && (
                                    <div
                                      className="col"
                                      style={{ paddingTop: '10px' }}
                                    >
                                      <label className="font-weight-700"></label>
                                      <input
                                        type="number"
                                        required
                                        disabled
                                        className="form-control"
                                        onKeyDown={preventNegativeValues}
                                        placeholder="Price"
                                        {...register('twentyfiveprice500')}
                                        onWheel={(e) => e.target.blur()}
                                      />
                                    </div>
                                  )}
                              </div>
                            )}
                            {twentyfivedwrfour && twentyfivedwrfour > 0 && (
                              <div className="row form-group">
                                <div className="col">
                                  <label className="font-weight-700">
                                    25 MM(420)*
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onKeyDown={preventNegativeValues}
                                    placeholder="Quantity"
                                    {...register('twentyfivequantity420')}
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </div>
                                {twentyfivequantity420 &&
                                  twentyfivequantity420 > 0 && (
                                    <div
                                      className="col"
                                      style={{ paddingTop: '10px' }}
                                    >
                                      <label className="font-weight-700"></label>
                                      <input
                                        type="number"
                                        required
                                        disabled
                                        className="form-control"
                                        onKeyDown={preventNegativeValues}
                                        placeholder="Price"
                                        {...register('twentyfiveprice420')}
                                        onWheel={(e) => e.target.blur()}
                                      />
                                    </div>
                                  )}
                              </div>
                            )}
                            {twentyfivedwrfourhund &&
                              twentyfivedwrfourhund > 0 && (
                                <div className="row form-group">
                                  <div className="col">
                                    <label className="font-weight-700">
                                      25 MM(400)*
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      onKeyDown={preventNegativeValues}
                                      placeholder="Quantity"
                                      {...register('twentyfivequantity400')}
                                      onWheel={(e) => e.target.blur()}
                                    />
                                  </div>
                                  {twentyfivequantity400hund &&
                                    twentyfivequantity400hund > 0 && (
                                      <div
                                        className="col"
                                        style={{ paddingTop: '10px' }}
                                      >
                                        <label className="font-weight-700"></label>
                                        <input
                                          type="number"
                                          required
                                          disabled
                                          className="form-control"
                                          onKeyDown={preventNegativeValues}
                                          placeholder="Price"
                                          {...register('twentyfiveprice400')}
                                          onWheel={(e) => e.target.blur()}
                                        />
                                      </div>
                                    )}
                                </div>
                              )}
                          </>
                        )}
                        <div className="form-group">
                          <label className="font-weight-700">
                            Loading & Truck Fare{' '}
                          </label>
                          <input
                            className="form-control"
                            onKeyDown={preventNegativeValues}
                            placeholder="Fare"
                            type="text"
                            {...register('fare')}
                          />
                        </div>
                        <div className="form-group">
                          <label className="font-weight-700">Total *</label>
                          <input
                            type="number"
                            required
                            disabled
                            className="form-control"
                            placeholder="Total"
                            {...register('total')}
                            onWheel={(e) => e.target.blur()}
                          />
                        </div>

                        <div className="row form-group">
                          <div className="col">
                            <label
                              className="font-weight-700"
                              style={{ paddingLeft: '18px' }}
                            >
                              Bank
                            </label>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flexWrap: 'wrap',
                              }}
                            >
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox13"
                                  onClick={(e) => handleOnChangeOneBank(e)}
                                  value="onebank04665"
                                  {...register('onebank')}
                                />
                                <label
                                  class="form-check-label"
                                  for="inlineCheckbox13"
                                >
                                  Onebank04665
                                </label>
                              </div>
                              {isCheckedOneBank && (
                                <>
                                  {oneBankAmountFields.map((field, index) => (
                                    <div key={field.id} className="form-group">
                                      <label className="font-weight-700">
                                        Amount
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        onKeyDown={preventNegativeValues}
                                        placeholder={`Amount(One Bank ${
                                          index + 1
                                        })`}
                                        {...register(
                                          `amountOneBank[${index}].amount`
                                        )}
                                      />
                                      <button
                                        type="button"
                                        className="site-button button-lg radius-no outline outline-2 mt-1"
                                        onClick={() =>
                                          oneBankAmountRemove(index)
                                        }
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  ))}
                                  <button
                                    type="button"
                                    className="site-button  "
                                    onClick={() =>
                                      oneBankAmountAppend({ amount: '' })
                                    }
                                  >
                                    Add Amount
                                  </button>
                                </>
                              )}
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox14"
                                  onClick={(e) => handleOnChangeIslamiBank(e)}
                                  value="islamibank36611"
                                  {...register('islamibank')}
                                />
                                <label
                                  class="form-check-label"
                                  for="inlineCheckbox14"
                                >
                                  Islamibank36611
                                </label>
                              </div>
                              {isCheckedIslamiBank && (
                                <>
                                  {islamiBankAmountFields.map(
                                    (field, index) => (
                                      <div
                                        key={field.id}
                                        className="form-group"
                                      >
                                        <label className="font-weight-700">
                                          Amount
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          onKeyDown={preventNegativeValues}
                                          placeholder={`Amount(Islami Bank ${
                                            index + 1
                                          })`}
                                          {...register(
                                            `amountIslamiBank[${index}].amount`
                                          )}
                                        />
                                        <button
                                          type="button"
                                          className="site-button button-lg radius-no outline outline-2 mt-1"
                                          onClick={() =>
                                            islamiBankAmountRemove(index)
                                          }
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    )
                                  )}
                                  <button
                                    type="button"
                                    className="site-button  "
                                    onClick={() =>
                                      islamiBankAmountAppend({ amount: '' })
                                    }
                                  >
                                    Add Amount
                                  </button>
                                </>
                              )}
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox15"
                                  onKeyDown={preventNegativeValues}
                                  onClick={(e) => handleOnChangeAlArafaBank(e)}
                                  value="alarafah18183"
                                  {...register('alarafah')}
                                />
                                <label
                                  class="form-check-label"
                                  for="inlineCheckbox15"
                                >
                                  Alarafah18183
                                </label>
                              </div>
                              {ischeckedalarafaBank && (
                                <>
                                  {alArafahBankAmountFields.map(
                                    (field, index) => (
                                      <div
                                        key={field.id}
                                        className="form-group"
                                      >
                                        <label className="font-weight-700">
                                          Amount
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          onKeyDown={preventNegativeValues}
                                          placeholder={`Amount(Al Arafah Bank ${
                                            index + 1
                                          })`}
                                          {...register(
                                            `amountAlArafahBank[${index}].amount`
                                          )}
                                        />
                                        <button
                                          type="button"
                                          className="site-button button-lg radius-no outline outline-2 mt-1"
                                          onClick={() =>
                                            alArafahBankAmountRemove(index)
                                          }
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    )
                                  )}
                                  <button
                                    type="button"
                                    className="site-button  "
                                    onClick={() =>
                                      alArafahBankAmountAppend({ amount: '' })
                                    }
                                  >
                                    Add Amount
                                  </button>
                                </>
                              )}
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox16"
                                  onClick={(e) => handleOnChangepubali(e)}
                                  value="pubali25303"
                                  {...register('pubali')}
                                />
                                <label
                                  class="form-check-label"
                                  for="inlineCheckbox16"
                                >
                                  Pubali25303
                                </label>
                              </div>
                              {ischeckedpubali && (
                                <>
                                  {pubaliBankAmountFields.map(
                                    (field, index) => (
                                      <div
                                        key={field.id}
                                        className="form-group"
                                      >
                                        <label className="font-weight-700">
                                          Amount
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          onKeyDown={preventNegativeValues}
                                          placeholder={`Amount(Pubali Bank ${
                                            index + 1
                                          })`}
                                          {...register(
                                            `amountPubaliBank[${index}].amount`
                                          )}
                                        />
                                        <button
                                          type="button"
                                          className="site-button button-lg radius-no outline outline-2 mt-1"
                                          onClick={() =>
                                            pubaliBankAmountRemove(index)
                                          }
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    )
                                  )}
                                  <button
                                    type="button"
                                    className="site-button  "
                                    onClick={() =>
                                      pubaliBankAmountAppend({ amount: '' })
                                    }
                                  >
                                    Add Amount
                                  </button>
                                </>
                              )}
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox17"
                                  onClick={(e) => handleOnChangestandard(e)}
                                  value="standard00078"
                                  {...register('standard')}
                                />
                                <label
                                  class="form-check-label"
                                  for="inlineCheckbox17"
                                >
                                  Standard00078
                                </label>
                              </div>
                              {ischeckedstandard && (
                                <>
                                  {standardBankAmountFields.map(
                                    (field, index) => (
                                      <div
                                        key={field.id}
                                        className="form-group"
                                      >
                                        <label className="font-weight-700">
                                          Amount
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          onKeyDown={preventNegativeValues}
                                          placeholder={`Amount(Standard Bank ${
                                            index + 1
                                          })`}
                                          {...register(
                                            `amountStandardBank[${index}].amount`
                                          )}
                                        />
                                        <button
                                          type="button"
                                          className="site-button button-lg radius-no outline outline-2 mt-1"
                                          onClick={() =>
                                            standardBankAmountRemove(index)
                                          }
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    )
                                  )}
                                  <button
                                    type="button"
                                    className="site-button  "
                                    onClick={() =>
                                      standardBankAmountAppend({ amount: '' })
                                    }
                                  >
                                    Add Amount
                                  </button>
                                </>
                              )}
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox18"
                                  onClick={(e) => handleOnChangejamuna(e)}
                                  value="jamuna15995"
                                  {...register('jamuna')}
                                />
                                <label
                                  class="form-check-label"
                                  for="inlineCheckbox18"
                                >
                                  Jamuna15995
                                </label>
                              </div>
                              {ischeckedjamuna && (
                                <>
                                  {jamunaBankAmountFields.map(
                                    (field, index) => (
                                      <div
                                        key={field.id}
                                        className="form-group"
                                      >
                                        <label className="font-weight-700">
                                          Amount
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          onKeyDown={preventNegativeValues}
                                          placeholder={`Amount(Jamuna Bank ${
                                            index + 1
                                          })`}
                                          {...register(
                                            `amountJamunaBank[${index}].amount`
                                          )}
                                        />
                                        <button
                                          type="button"
                                          className="site-button button-lg radius-no outline outline-2 mt-1"
                                          onClick={() =>
                                            jamunaBankAmountRemove(index)
                                          }
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    )
                                  )}
                                  <button
                                    type="button"
                                    className="site-button  "
                                    onClick={() =>
                                      jamunaBankAmountAppend({ amount: '' })
                                    }
                                  >
                                    Add Amount
                                  </button>
                                </>
                              )}
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox19"
                                  onClick={(e) => handleOnChangeUttara(e)}
                                  value="uttara15447"
                                  {...register('uttara')}
                                />
                                <label
                                  class="form-check-label"
                                  for="inlineCheckbox19"
                                >
                                  Uttara15447
                                </label>
                              </div>
                              {ischeckedUttara && (
                                <>
                                  {uttaraBankAmountFields.map(
                                    (field, index) => (
                                      <div
                                        key={field.id}
                                        className="form-group"
                                      >
                                        <label className="font-weight-700">
                                          Amount
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          onKeyDown={preventNegativeValues}
                                          placeholder={`Amount(Uttara Bank ${
                                            index + 1
                                          })`}
                                          {...register(
                                            `amountUttaraBank[${index}].amount`
                                          )}
                                        />
                                        <button
                                          type="button"
                                          className="site-button button-lg radius-no outline outline-2 mt-1"
                                          onClick={() =>
                                            uttaraBankAmountRemove(index)
                                          }
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    )
                                  )}
                                  <button
                                    type="button"
                                    className="site-button  "
                                    onClick={() =>
                                      uttaraBankAmountAppend({ amount: '' })
                                    }
                                  >
                                    Add Amount
                                  </button>
                                </>
                              )}
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox20"
                                  onClick={(e) => handleOnChangePremier(e)}
                                  value="premier05382"
                                  {...register('premier')}
                                />
                                <label
                                  class="form-check-label"
                                  for="inlineCheckbox20"
                                >
                                  Premier05382
                                </label>
                              </div>
                              {isChekcedPremier && (
                                <>
                                  {premierBankAmountFields.map(
                                    (field, index) => (
                                      <div
                                        key={field.id}
                                        className="form-group"
                                      >
                                        <label className="font-weight-700">
                                          Amount
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          onKeyDown={preventNegativeValues}
                                          placeholder={`Amount(Premier Bank ${
                                            index + 1
                                          })`}
                                          {...register(
                                            `amountPremierBank[${index}].amount`
                                          )}
                                        />
                                        <button
                                          type="button"
                                          className="site-button button-lg radius-no outline outline-2 mt-1"
                                          onClick={() =>
                                            premierBankAmountRemove(index)
                                          }
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    )
                                  )}
                                  <button
                                    type="button"
                                    className="site-button  "
                                    onClick={() =>
                                      premierBankAmountAppend({ amount: '' })
                                    }
                                  >
                                    Add Amount
                                  </button>
                                </>
                              )}
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox21"
                                  onClick={(e) => handleOnChangeJanata(e)}
                                  value="janata47209"
                                  {...register('janata')}
                                />
                                <label
                                  class="form-check-label"
                                  for="inlineCheckbox21"
                                >
                                  Janata47209
                                </label>
                              </div>
                              {isChekcedJanata && (
                                <>
                                  {janataBankAmountFields.map(
                                    (field, index) => (
                                      <div
                                        key={field.id}
                                        className="form-group"
                                      >
                                        <label className="font-weight-700">
                                          Amount
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          onKeyDown={preventNegativeValues}
                                          placeholder={`Amount(Janata Bank ${
                                            index + 1
                                          })`}
                                          {...register(
                                            `amountJanataBank[${index}].amount`
                                          )}
                                        />
                                        <button
                                          type="button"
                                          className="site-button button-lg radius-no outline outline-2 mt-1"
                                          onClick={() =>
                                            janataBankAmountRemove(index)
                                          }
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    )
                                  )}
                                  <button
                                    type="button"
                                    className="site-button  "
                                    onClick={() =>
                                      janataBankAmountAppend({ amount: '' })
                                    }
                                  >
                                    Add Amount
                                  </button>
                                </>
                              )}
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox22"
                                  onClick={(e) => handleOnChangeAgranee(e)}
                                  value="agranee94135"
                                  {...register('agranee')}
                                />
                                <label
                                  class="form-check-label"
                                  for="inlineCheckbox22"
                                >
                                  Agranee94135
                                </label>
                              </div>
                              {isChekcedAgranee && (
                                <>
                                  {agraneeBankAmountFields.map(
                                    (field, index) => (
                                      <div
                                        key={field.id}
                                        className="form-group"
                                      >
                                        <label className="font-weight-700">
                                          Amount
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          onKeyDown={preventNegativeValues}
                                          placeholder={`Amount(Agranee Bank ${
                                            index + 1
                                          })`}
                                          {...register(
                                            `amountAgraneeBank[${index}].amount`
                                          )}
                                        />
                                        <button
                                          type="button"
                                          className="site-button button-lg radius-no outline outline-2 mt-1"
                                          onClick={() =>
                                            agraneeBankAmountRemove(index)
                                          }
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    )
                                  )}
                                  <button
                                    type="button"
                                    className="site-button  "
                                    onClick={() =>
                                      agraneeBankAmountAppend({ amount: '' })
                                    }
                                  >
                                    Add Amount
                                  </button>
                                </>
                              )}
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox23"
                                  onClick={(e) => handleOnChangeSonali(e)}
                                  value="sonali02908"
                                  {...register('sonali')}
                                />
                                <label
                                  class="form-check-label"
                                  for="inlineCheckbox23"
                                >
                                  Sonali02908
                                </label>
                              </div>
                              {isChekcedSonali && (
                                <>
                                  {sonaliBankAmountFields.map(
                                    (field, index) => (
                                      <div
                                        key={field.id}
                                        className="form-group"
                                      >
                                        <label className="font-weight-700">
                                          Amount
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          onKeyDown={preventNegativeValues}
                                          placeholder={`Amount(Sonali Bank ${
                                            index + 1
                                          })`}
                                          {...register(
                                            `amountSonaliBank[${index}].amount`
                                          )}
                                        />
                                        <button
                                          type="button"
                                          className="site-button button-lg radius-no outline outline-2 mt-1"
                                          onClick={() =>
                                            sonaliBankAmountRemove(index)
                                          }
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    )
                                  )}
                                  <button
                                    type="button"
                                    className="site-button  "
                                    onClick={() =>
                                      sonaliBankAmountAppend({ amount: '' })
                                    }
                                  >
                                    Add Amount
                                  </button>
                                </>
                              )}
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox24"
                                  onClick={(e) => handleOnChangeDhaka(e)}
                                  value="dhaka06996"
                                  {...register('dhaka')}
                                />
                                <label
                                  class="form-check-label"
                                  for="inlineCheckbox24"
                                >
                                  Dhaka06996
                                </label>
                              </div>
                              {isChekcedDhaka && (
                                <>
                                  {dhakaBankAmountFields.map((field, index) => (
                                    <div key={field.id} className="form-group">
                                      <label className="font-weight-700">
                                        Amount
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        onKeyDown={preventNegativeValues}
                                        placeholder={`Amount(Dhaka Bank ${
                                          index + 1
                                        })`}
                                        {...register(
                                          `amountDhakaBank[${index}].amount`
                                        )}
                                      />
                                      <button
                                        type="button"
                                        className="site-button button-lg radius-no outline outline-2 mt-1"
                                        onClick={() =>
                                          dhakaBankAmountRemove(index)
                                        }
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  ))}
                                  <button
                                    type="button"
                                    className="site-button  "
                                    onClick={() =>
                                      dhakaBankAmountAppend({ amount: '' })
                                    }
                                  >
                                    Add Amount
                                  </button>
                                </>
                              )}
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox25"
                                  onClick={(e) => handleOnChangeMercantile(e)}
                                  value="mercantile60749"
                                  {...register('mercantile')}
                                />
                                <label
                                  class="form-check-label"
                                  for="inlineCheckbox25"
                                >
                                  Mercantile60749
                                </label>
                              </div>
                              {isChekcedMercantile && (
                                <>
                                  {mercantileBankAmountFields.map(
                                    (field, index) => (
                                      <div
                                        key={field.id}
                                        className="form-group"
                                      >
                                        <label className="font-weight-700">
                                          Amount
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          onKeyDown={preventNegativeValues}
                                          placeholder={`Amount(Mercantile Bank ${
                                            index + 1
                                          })`}
                                          {...register(
                                            `amountMercantileBank[${index}].amount`
                                          )}
                                        />
                                        <button
                                          type="button"
                                          className="site-button button-lg radius-no outline outline-2 mt-1"
                                          onClick={() =>
                                            mercantileBankAmountRemove(index)
                                          }
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    )
                                  )}
                                  <button
                                    type="button"
                                    className="site-button  "
                                    onClick={() =>
                                      mercantileBankAmountAppend({ amount: '' })
                                    }
                                  >
                                    Add Amount
                                  </button>
                                </>
                              )}
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox26"
                                  onClick={(e) => handleOnChangeRupali(e)}
                                  value="rupali03331"
                                  {...register('rupali')}
                                />
                                <label
                                  class="form-check-label"
                                  for="inlineCheckbox26"
                                >
                                  Rupali03331
                                </label>
                              </div>
                              {isChekcedRupali && (
                                <>
                                  {rupaliBankAmountFields.map(
                                    (field, index) => (
                                      <div
                                        key={field.id}
                                        className="form-group"
                                      >
                                        <label className="font-weight-700">
                                          Amount
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          onKeyDown={preventNegativeValues}
                                          placeholder={`Amount(Rupali Bank ${
                                            index + 1
                                          })`}
                                          {...register(
                                            `amountRupaliBank[${index}].amount`
                                          )}
                                        />
                                        <button
                                          type="button"
                                          className="site-button button-lg radius-no outline outline-2 mt-1"
                                          onClick={() =>
                                            rupaliBankAmountRemove(index)
                                          }
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    )
                                  )}
                                  <button
                                    type="button"
                                    className="site-button  "
                                    onClick={() =>
                                      rupaliBankAmountAppend({ amount: '' })
                                    }
                                  >
                                    Add Amount
                                  </button>
                                </>
                              )}
                              <div class="form-check form-check-inline">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox27"
                                  onClick={(e) => handleOnChangeUnited(e)}
                                  value="unitedCommercial09965"
                                  {...register('unitedCommercial')}
                                />
                                <label
                                  class="form-check-label"
                                  for="inlineCheckbox27"
                                >
                                  UnitedCommercial09965
                                </label>
                              </div>
                              {isChekcedUnited && (
                                <>
                                  {unitedBankAmountFields.map(
                                    (field, index) => (
                                      <div
                                        key={field.id}
                                        className="form-group"
                                      >
                                        <label className="font-weight-700">
                                          Amount
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          onKeyDown={preventNegativeValues}
                                          placeholder={`Amount(United Commercial Bank ${
                                            index + 1
                                          })`}
                                          {...register(
                                            `amountUnitedBank[${index}].amount`
                                          )}
                                        />
                                        <button
                                          type="button"
                                          className="site-button button-lg radius-no outline outline-2 mt-1"
                                          onClick={() =>
                                            unitedBankAmountRemove(index)
                                          }
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    )
                                  )}
                                  <button
                                    type="button"
                                    className="site-button  "
                                    onClick={() =>
                                      unitedBankAmountAppend({ amount: '' })
                                    }
                                  >
                                    Add Amount
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        </div>

                        <div row form-group>
                          <div className="col">
                            <div>
                              <div class="form-check form-check-inline">
                                <input
                                  class=""
                                  type="checkbox"
                                  id="inlineCheckboxdelivery"
                                  // checked={isChecked}
                                  // onClick={(e) => handleOnChange(e)}
                                  value="true"
                                  {...register('paymentAfterDelivery')}
                                />
                                <label
                                  class="form-check-label"
                                  for="inlineCheckboxdelivery"
                                >
                                  Payment After Delivery
                                </label>
                              </div>
                            </div>

                            <div>
                              <div class="form-check form-check-inline">
                                <input
                                  class=""
                                  type="checkbox"
                                  id="inlineCheckboxcredit"
                                  // checked={isChecked}
                                  // onClick={(e) => handleOnChange(e)}
                                  value="true"
                                  {...register('credit')}
                                />
                                <label
                                  class="form-check-label"
                                  for="inlineCheckboxcredit"
                                >
                                  Credit
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="font-weight-700">Reference *</label>
                          <input
                            type="text"
                            required
                            className="form-control"
                            placeholder="Reference"
                            {...register('reference')}
                          />
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div className="text-right m-t15">
                            <button
                              className="site-button button-lg radius-no outline outline-2"
                              type="submit"
                            >
                              SUBMIT
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default OrderNow;
