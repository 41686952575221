import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header12';
import Footer from '../layout/footer4';
import { getCoursesByType } from '../server';

const bg = require('../../images/banner/bnr4.jpg');

const Shop = () => {
    const [filter, setFilter] = useState('FREE'); // Add state for filter

    const [courseData,setCourseData] = useState([]);

    const products = Array.from({ length: 12 }).map((_, index) => ({
        id: index + 1,
        name: `Product ${index + 1}`,
        price: 192,
        originalPrice: 232,
        image: require(`../../images/product/item${(index % 8) + 1}.jpg`),
        type: index % 2 === 0 ? 'PAID' : 'FREE' // Alternate between PAID and FREE
    }));

    const filteredProducts = filter === 'ALL' ? products : products.filter(product => product.type === filter);


    useEffect(() => {

        getCoursesByType({ type: filter })
            .then((res) => {
                console.log('res', res);
                if (res) {
                    setCourseData(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
     
    }, [filter])
    

    return (
        <>
            <Header />
            <div className="page-content bg-white">
                <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: `url(${bg})` }}>
                    <div className="container">
                        <div className="dlab-bnr-inr-entry">
                            <h1 className="text-white">Training Courses</h1>
                            <div className="breadcrumb-row">
                                <ul className="list-inline">
                                    <li><Link to="/">Home</Link></li>
                                    <li>Training Courses</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-full content-inner">
                    <div className="container">
                        <div className="row mb-4">
                            <div className="col-lg-12">
                                <div className="d-flex justify-content-end">
                                    <label htmlFor="filter" style={{ marginRight: '10px', marginTop:'10px' }}>Filter by:</label>
                                    <select 
                                        className="form-control" 
                                        style={{
                                            fontSize: '1em',
                                            padding: '10px 15px',
                                            borderRadius: '8px',
                                            border: '1px solid #ddd',
                                            backgroundColor: '#fff',
                                            color: '#333',
                                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                            transition: 'all 0.3s ease',
                                            width: '200px'
                                        }} 
                                        value={filter} 
                                        onChange={(e) => setFilter(e.target.value)}
                                    >
                                        <option value="PAID">Paid</option>
                                        <option value="FREE">Free</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {courseData.map((product) => (
                                <div className="col-lg-3 col-md-4 col-sm-6" key={product.id}>
                                    <div className="item-box m-b10">
                                        <div className="item-img">
                                            <img src={`https://rest.alcumusbd.org/abc/${product?.images[0]?.url}`} alt={product.title} />
                                            <div className="item-info-in">
                                                <ul>
                                                    {/* <li><Link to="/shop-cart"><i className="ti-shopping-cart"></i></Link></li> */}
                                                    <li><Link to={`/shop-product-details/${product.id}`}><i className="ti-eye"></i></Link></li>
                                                    {/* <li><Link to="#"><i className="ti-heart"></i></Link></li> */}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="item-info text-center text-black p-a10">
                                            <h6 className="item-title font-weight-500">
                                                <Link to={`/shop-product-details/${product.id}`}>{product.title}</Link>
                                            </h6>
                                            {/* <ul className="item-review">
                                                <li><i className="fa fa-star"></i></li>
                                                <li><i className="fa fa-star"></i></li>
                                                <li><i className="fa fa-star"></i></li>
                                                <li><i className="fa fa-star-half-o"></i></li>
                                                <li><i className="fa fa-star-o"></i></li>
                                            </ul> */}
                                           {
                                            product?.type === 'PAID' ? (
                                                <h4 className="item-price">
                                                {/* <del>${product.price ? product?.price: "FREE"}</del>  */}
                                                  <span className="text-primary">TK {product.price ? product?.price: "FREE"}</span>
                                                </h4>)
                                                : (
                                                    <h4 className="item-price">
                                                  <span className="text-primary"> {"Free"}</span>
                                                    </h4>
                                                )
                                           }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Uncomment the below section if you want to include the service info */}
                {/* <div className="section-full p-t50 p-b20 bg-primary text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-lg-4">
                                <div className="icon-bx-wraper left shop-service-info m-b30">
                                    <div className="icon-md text-black radius"> 
                                        <Link to="#" className="icon-cell text-white"><i className="fa fa-gift"></i></Link> 
                                    </div>
                                    <div className="icon-content">
                                        <h5 className="dlab-tilte">Free shipping on orders $60+</h5>
                                        <p>Order more than 60$ and you will get free shipping Worldwide. More info.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4">
                                <div className="icon-bx-wraper left shop-service-info m-b30">
                                    <div className="icon-md text-black radius"> 
                                        <Link to="#" className="icon-cell text-white"><i className="fa fa-plane"></i></Link> 
                                    </div>
                                    <div className="icon-content">
                                        <h5 className="dlab-tilte">Worldwide delivery</h5>
                                        <p>We deliver to the following countries: USA, Canada, Europe, Australia</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4">
                                <div className="icon-bx-wraper left shop-service-info m-b30">
                                    <div className="icon-md text-black radius"> 
                                        <Link to="#" className="icon-cell text-white"><i className="fa fa-history"></i></Link> 
                                    </div>
                                    <div className="icon-content">
                                        <h5 className="dlab-tilte">60 days money back guarantee!</h5>
                                        <p>Not happy with our product, feel free to return it, we will refund 100% your money!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>                
            <Footer  />                
        </>
    );
};

export default Shop;
