import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
//import responsive from './slider-resonsive'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function ProjectSlider1({ data }) {
  console.log('projectslider', data);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: Math.min(4, data?.length),
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: Math.min(4, data?.length),
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: Math.min(2, data?.length),
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: Math.min(1, data?.length),
        },
      },
    ],
  };
  return (
    <>
      <Slider
        {...settings}
        className="img-carousel-dots-nav btn-style-1 icon-2"
      >
        {data &&
          data.map((item, id) => (
            <div className="item">
              {console.log('item is', item)}
              <div className="dlab-box project-bx">
                <div className="dlab-media radius-sm dlab-img-overlay1 dlab-img-effect zoom">
                  <Link to="/certifications">
                    <img
                      src={`https://rest.alcumusbd.org/abc/${item.images[0].url}`}
                      alt=""
                    />
                  </Link>
                </div>
                <div className="dlab-info">
                  <h5 className="dlab-title">
                    <Link to="/certifications">{item.name}</Link>
                  </h5>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${item?.details.slice(0, 180)}`,
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
      </Slider>
    </>
  );
}
export default ProjectSlider1;
