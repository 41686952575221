import React, { useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Button, Form, FormControl } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import Sticky from 'react-stickynode';
const options = [
  { page: 'Home', link: 'home' },
  { page: 'About Us', link: 'about-us' },
  { page: 'Mission,Vision & Values', link: 'mission' },
  { page: 'Corporate Information', link: 'corporate' },
  { page: 'Board Of Directors', link: 'board-of-directors' },
  // { page: 'Nomination & Remuneration', link: 'nominatiom-and-renumeration' },
  // { page: 'Audit Commitee', link: 'audit-commitee' },
  { page: 'Key Management', link: 'key-management' },
  // { page: 'Sister Concern Of JSRM', link: 'sister-concern' },
  { page: 'Our Products', link: 'products' },
  { page: 'Quality Test', link: 'quality-test' },
  { page: 'Quality Control', link: 'quality-control' },
  { page: 'Certifications', link: 'certification' },
  { page: 'Pre Sales Service', link: 'pre-sales-service' },
  { page: 'Post Sales Service', link: 'post-sales-service' },
  { page: 'Supply Chain', link: 'supply-chain' },
  { page: 'Environmental', link: 'environmental' },
  { page: 'Remarkable Customers', link: 'remarkable-customers' },
  // { page: 'Financial', link: 'financial' },
  { page: 'Social', link: 'social' },
  { page: 'Technological', link: 'technological' },
  { page: 'Compliance', link: 'compliance' },
  { page: 'Hr Policy', link: 'hr-policy' },
  { page: 'Available Jobs', link: 'available-jobs' },
  { page: 'Events & Publications', link: 'news-events' },
  { page: 'Brochure', link: 'brochure' },
  // { page: 'Leaflet', link: 'leaflet' },
  { page: 'JSRM Journey', link: 'journey' },
  { page: 'Television Commercials', link: 'tvc' },
  // { page: 'Photo Gallery', link: 'gallery' },
  { page: 'Contact Us', link: 'contact-1' },
  { page: '', link: 'news' },
  { page: 'Online CV', link: 'onlinecv' },
  { page: 'Order Now', link: 'loginPage' },
];
class Footer12 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { selected } = this.state;
    if (selected.length && prevState.selected !== selected) {
      this.props.history.push(`/${selected[0].link}`);
    }
  }
  state = {
    fixed: false,
  };

  componentDidMount() {
    // sidebar open/close

    var btn = document.querySelector('.navicon');
    var nav = document.querySelector('.header-nav');

    function toggleFunc() {
      btn.classList.toggle('open');
      nav.classList.toggle('show');
    }

    btn.addEventListener('click', toggleFunc);

    // Sidenav li open close
    var navUl = [].slice.call(
      document.querySelectorAll('.header-nav > ul > li')
    );
    for (var y = 0; y < navUl.length; y++) {
      navUl[y].addEventListener('click', function () {
        checkLi(this);
      });
    }

    function checkLi(current) {
      const active = current.classList.contains('open');
      navUl.forEach((el) => el.classList.remove('open'));
      //current.classList.add('open');

      if (active) {
        current.classList.remove('open');
        console.log('active');
      } else {
        current.classList.add('open');
        console.log('close');
      }
    }
  }

  handleStateChange = (status) => {
    const fixed = status.status === Sticky.STATUS_FIXED ? true : false;

    this.setState({
      fixed,
    });
  };

  render() {
    const active = this.state.fixed ? 'is-fixed' : '';
    const { selected } = this.state;
    return (
      <>
        <Sticky
          innerZ={999}
          enabled={true}
          onStateChange={this.handleStateChange}
        >
          <header className="site-header mo-left header header-transparent navstyle2">
            <div
              class={`sticky-header main-bar-wraper ${active} navbar-expand-lg`}
            >
              <div className="main-bar clearfix ">
                <div className="container clearfix">
                  <div className="logo-header mostion">
                    <Link to="/">
                      <img src={require('../../images/logo2.png')} alt="" />
                    </Link>
                  </div>

                  <button
                    className="navbar-toggler collapsed navicon justify-content-end"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>

                  <div className="extra-nav">
                    <div className="extra-cell">
                      {/* <button
                        id="quik-search-btn"
                        type="button"
                        className="site-button-link"
                      >
                        <i className="la la-search"></i>
                      </button> */}

                      <Form inline>
                        <div style={{ display: 'flex', zIndex: '0' }}>
                          {/* <FormControl
              onChange={handleSearchInput}
              
              type="text"
              placeholder="Search"
              className="mr-sm-2"
            /> */}
                          <Typeahead
                            id="basic-example"
                            labelKey={(option) => `${option.page}`}
                            onChange={(selected) => this.setState({ selected })}
                            options={options}
                            placeholder="Search A Page.."
                            selected={selected}
                            minLength={1}
                            className="transparent-bg"
                          />
                          <Button
                            onClick={this.handleSearchSubmit}
                            variant="outline-info"
                            className="ml-2"
                          >
                            <i className="la la-search"></i>
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                  <div
                    className="header-nav navbar-collapse collapse justify-content-end"
                    id="navbarNavDropdown"
                  >
                    <div className="logo-header d-md-block d-lg-none">
                      <Link to="/">
                        <img src={require('../../images/logo2.png')} alt="" />
                      </Link>
                    </div>
                    <ul className="nav navbar-nav">
                      <li className=" has-mega-menu homedemo">
                        {' '}
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        {' '}
                        <Link to={'#'}>
                          About Us<i className="fa fa-chevron-down"></i>
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link to={'/about-us'}>About Us</Link>

                            <li>
                              <Link to={'/mission'}>
                                Mission ,Vision & Values
                              </Link>
                            </li>
                            <li>
                              <Link to={'/corporate'}>
                                Corporate Information
                              </Link>
                            </li>
                            {/* <li>
                            <Link to={'/board-of-directors'}>
                              Board Of Directors
                            </Link>
                          </li> */}
                            {/* <li>
                            <Link to={'/nominatiom-and-renumeration'}>
                              Nomination & Remuneration
                            </Link>
                          </li> */}
                            {/* <li>
                            <Link to={'/audit-commitee'}>Audit Commitee</Link>
                          </li> */}
                            <li>
                              <Link to={'/our-client'}>Our Client</Link>
                            </li>
                            {/* <li>
                            <Link to={'/sister-concern'}>
                              Sister Concern Of JSRM
                            </Link>
                          </li> */}
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="#">
                          Services<i className="fa fa-chevron-down"></i>
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link to="/certifications">Certifications</Link>
                          </li>
                          {/* <li><Link to="/certification">Certifications</Link></li> */}
                          <li>
                            <Link to={'/compliances'}>Compliances</Link>
                          </li>
                          <li>
                            <Link to={'/2nd-party-auditing'}>
                              2nd Party Auditing
                            </Link>
                          </li>
                          <li>
                            <Link to={'/niche-need-audits'}>
                              Niche & Need Based Audits
                            </Link>
                          </li>{' '}
                          <li>
                            <Link to={'/standard-development'}>
                              Standard Development
                            </Link>
                          </li>
                          <li>
                            <Link to={'/pest-control'}>
                              Pest Control Program
                            </Link>
                          </li>
                        </ul>
                      </li>
                      {/* <li className="has-mega-menu"> 
											<Link to={'#'}>Blog<i className="fa fa-chevron-down"></i></Link>
											<ul className="mega-menu">
												<li> <Link to={'#'}>Blog</Link>
													<ul>
														<li><Link to={'/blog-half-img'}>Half image</Link></li>
														<li><Link to={'/blog-half-img-sidebar'}>Half image sidebar</Link></li>
														<li><Link to={'/blog-half-img-left-sidebar'}>Half image sidebar left</Link></li>
														<li><Link to={'/blog-large-img'}>Large image</Link></li>
													</ul>
												</li>
												<li> <Link to={'#'}>Blog</Link>
													<ul>
														<li><Link to={'blog-large-img-sidebar'}>Large image sidebar</Link></li>
														<li><Link to={'blog-large-img-left-sidebar'}>Large image sidebar left</Link></li>
														<li><Link to={'blog-grid-2'}>Grid 2</Link></li>
														<li><Link to={'blog-grid-2-sidebar'}>Grid 2 sidebar</Link></li>
													</ul>
												</li>
												<li> <Link to={'#'}>Blog</Link>
													<ul>
														<li><Link to={'blog-grid-2-sidebar-left'}>Grid 2 sidebar left</Link></li>
														<li><Link to={'blog-grid-3'}>Grid 3</Link></li>
														<li><Link to={'blog-grid-3-sidebar'}>Grid 3 sidebar</Link></li>
														<li><Link to={'blog-grid-3-sidebar-left'}>Grid 3 sidebar left</Link></li>
													</ul>
												</li>
												<li> <Link to={'#'}>Blog</Link>
													<ul>
														<li><Link to={'blog-grid-4'}>Grid 4</Link></li>
														<li><Link to={'blog-single'}>Single</Link></li>
														<li><Link to={'blog-single-sidebar'}>Single sidebar</Link></li>
														<li><Link to={'blog-single-left-sidebar'}>Single sidebar right</Link></li>
													</ul>
												</li>
											</ul>
										</li> */}
                      {/* <li className="has-mega-menu"> <Link to={'#'}>Element<i className="fa fa-chevron-down"></i></Link>
											<ul className="mega-menu">
												<li><Link to={'#'}>Element</Link>
													<ul>
														<li><Link to={'shortcode-buttons'}><i className="ti-mouse"></i> Buttons</Link></li>
														<li><Link to={'shortcode-icon-box-styles'}><i className="ti-layout-grid2"></i> Icon box styles</Link></li>
														<li><Link to={'shortcode-pricing-table'}><i className="ti-layout-grid2-thumb"></i> Pricing table</Link></li>
														<li><Link to={'shortcode-team'}><i className="ti-user"></i> Team</Link></li>
													</ul>
												</li>
												<li> <Link to={'#'}>Element</Link>
													<ul>
														<li><Link to={'shortcode-image-box-content'}><i className="ti-image"></i> Image box content</Link></li>
														<li><Link to={'shortcode-counters'}><i className="ti-timer"></i> Counters</Link></li>
														<li><Link to={'shortcode-shop-widgets'}><i className="ti-shopping-cart"></i> Shop Widgets</Link></li>
														<li><Link to={'shortcode-dividers'}><i className="ti-layout-list-post"></i> Dividers</Link></li>
													</ul>
												</li>
												<li> <Link to={'#'}>Element</Link>
													<ul>
														<li><Link to={'shortcode-images-effects'}><i className="ti-layout-media-overlay"></i> Images effects</Link></li>
														<li><Link to={'shortcode-testimonials'}><i className="ti-comments"></i> Testimonials</Link></li>
														<li><Link to={'shortcode-pagination'}><i className="ti-more"></i> Pagination</Link></li>
														<li><Link to={'shortcode-alert-box'}><i className="ti-alert"></i> Alert box</Link></li>
													</ul>
												</li>
												<li> <Link to={'#'}>Element</Link>
													<ul>
														<li><Link to={'shortcode-icon-box'}><i className="ti-layout-media-left-alt"></i> Icon Box</Link></li>
														<li><Link to={'shortcode-list-group'}><i className="ti-list"></i> List group</Link></li>
														<li><Link to={'shortcode-title-separators'}><i className="ti-layout-line-solid"></i> Title Separators</Link></li>
													</ul>
												</li>
											</ul>
										</li> */}
                      <li className=" has-mega-menu homedemo">
                        <Link to="/gallery">Photo Gallery</Link>
                      </li>
                      <li>
                        <Link to="#">
                          News & Media<i className="fa fa-chevron-down"></i>
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link to="/news-events">Events & Publications</Link>
                          </li>

                          <li>
                            <Link to="/tvc">Video Commercials</Link>
                          </li>
                          <li>
                          <Link to="/brochure">Brochure</Link>
                        </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="#">
                          Career<i className="fa fa-chevron-down"></i>
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link to="/hr-policy">HR Policy</Link>
                          </li>
                          <li>
                            <Link to="/available-jobs">Available Jobs</Link>
                          </li>
                          <li>
                            <Link to="/onlinecv">Online CV</Link>
                          </li>
                        </ul>
                      </li>
                      <li className=" has-mega-menu homedemo">
                        <Link to="/shop">Training</Link>
                      </li>
                      <li>
                        <Link to="/contact-1">Contact Us</Link>
                      </li>

                      <li>
                        <Link to="/login-page">Log In</Link>
                      </li>
                    </ul>
                    <div className="dlab-social-icon">
                      <ul>
                        <li>
                          <Link
                            className="site-button sharp-sm fa fa-facebook"
                            to="/javascript:void(0);"
                          ></Link>
                        </li>
                        <li>
                          <Link
                            className="site-button sharp-sm fa fa-twitter"
                            to="/javascript:void(0);"
                          ></Link>
                        </li>
                        <li>
                          <Link
                            className="site-button sharp-sm fa fa-linkedin"
                            to="/javascript:void(0);"
                          ></Link>
                        </li>
                        <li>
                          <Link
                            className="site-button sharp-sm fa fa-instagram"
                            to="/javascript:void(0);"
                          ></Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </Sticky>
      </>
    );
  }
}
export default withRouter(Footer12);
