import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header12';
import Footer from '../layout/footer4';
import { useState } from 'react';
import { useEffect } from 'react';
import {
  getAllAbout,
  getBanners,
  getHRPolicy,
  getJobs,
  getNewsAndArticles,
  getQualityAssurance,
  getServices,
  getSustainability,
} from '../server';
import BlogSlider1 from '../element/blogSlider1';
import moment from 'moment';

var bnr3 = require('./../../images/banner/bnr3.jpg');

function Jobs() {
  const [policy, setPolicy] = useState(null);
  const [news, setNews] = useState(null);
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    getBanners({ type: 'career', subType: 'available jobs' })
      .then((res) => {
        console.log('resAboutUs', res);
        if (res) {
          setBanner(res?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getJobs({})
      .then((res) => {
        console.log('resAboutUs', res);
        if (res) {
          setPolicy(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    getNewsAndArticles()
      .then((res) => {
        console.log('news', res);
        if (res) {
          setNews(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Header />

      {banner && (
        <div className="page-content bg-white">
          <div
            className="dlab-bnr-inr overlay-black-middle bg-pt"
            style={{
              backgroundImage: `url('https://rest.alcumusbd.org/abc/${banner.image.url}')`,
            }}
          >
            <div className="container">
              <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">Available Jobs</h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li>
                      <Link to={'/'}>Home</Link>
                    </li>
                    <li>Available Jobs</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="content-block">
            {policy &&
              policy.map((item, index) => (
                <div className="section-full content-inner bg-white">
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-lg-6 col-md-12 m-b30">
                        <div className="our-story">
                          {/* <span>Our Products</span> */}
                          <h2 className="title">{item?.title}</h2>
                          <p style={{}}>{item?.body}</p>
                          <p
                            style={{
                              display: 'flex',
                              justifyContent: 'start',
                            }}
                          >
                            <h4>Contract Type :</h4>{' '}
                            <p style={{ marginLeft: '10px' }}>
                              {item?.contractType}
                            </p>
                          </p>
                          <p
                            style={{
                              display: 'flex',
                              justifyContent: 'start',
                            }}
                          >
                            <h4>Location :</h4>{' '}
                            <p style={{ marginLeft: '10px' }}>
                              {item?.location}
                            </p>
                          </p>
                          <p
                            style={{
                              display: 'flex',
                              justifyContent: 'start',
                            }}
                          >
                            <h4>Number Of Vacancy :</h4>{' '}
                            <p style={{ marginLeft: '10px' }}>
                              {item?.numberOfVacancy}
                            </p>
                          </p>
                          <p
                            style={{ display: 'flex', justifyContent: 'start' }}
                          >
                            <h4>Educational Requirements:</h4>{' '}
                            <p style={{ marginLeft: '10px' }}>
                              {item?.education}
                            </p>
                          </p>
                          <div style={{ display: 'flex' }}>
                            <p>
                              <p style={{ marginLeft: '10px' }}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: `${item?.description}`,
                                  }}
                                />
                              </p>
                            </p>
                            <p></p>
                          </div>
                          <p
                            style={{
                              display: 'flex',
                              justifyContent: 'start',
                            }}
                          >
                            <h4>Experience :</h4>{' '}
                            <p style={{ marginLeft: '10px' }}>
                              {item?.experience}
                            </p>
                          </p>

                          <p
                            style={{
                              display: 'flex',
                              justifyContent: 'start',
                            }}
                          >
                            <h4>Department :</h4>{' '}
                            <p style={{ marginLeft: '10px' }}>
                              {item?.department}
                            </p>
                          </p>
                          <p
                            style={{
                              display: 'flex',
                              justifyContent: 'start',
                            }}
                          >
                            <h4>Expiry Date :</h4>{' '}
                            <p style={{ marginLeft: '10px' }}>
                              {moment(item?.expireDate).format('DD/MM/YYYY')}
                            </p>
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 m-b30 ">
                        {/* <img
                      src={`https://rest.alcumusbd.org/abc/${item?.image.url}`}
                      className="radius-sm"
                      alt=""
                    /> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            <div
              className="section-full content-inner bg-white wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.6s"
            >
              <div className="container">
                <div className="section-head text-black text-center">
                  <h2 className="title">News & Articles</h2>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <BlogSlider1 data={news} imgEffect="rotate" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}
export default Jobs;
