import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header12';
import Footer from '../layout/footer4';
import { useState } from 'react';
import { useEffect } from 'react';
import {
  getAllAbout,
  getAllProducts,
  getBanners,
  getNewsAndArticles,
} from '../server';
import BlogSlider1 from '../element/blogSlider1';

var bnr3 = require('./../../images/banner/bnr2.jpg');

function Products() {
  const [products, setProducts] = useState(null);
  const [aboutUs, setAboutUs] = useState(null);
  const [news, setNews] = useState(null);
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    getBanners({ type: 'products & services', subType: 'products' })
      .then((res) => {
        console.log('resAboutUs', res);
        if (res) {
          setBanner(res?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getAllProducts()
      .then((res) => {
        console.log('products', res);
        if (res) {
          setProducts(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getNewsAndArticles()
      .then((res) => {
        console.log('news', res);
        if (res) {
          setNews(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Header />
      {/* {banner && (
        <div className="page-content bg-white">
          <div
            className="dlab-bnr-inr overlay-black-middle bg-pt"
            style={{
              backgroundImage: `url(' https://rest.alcumusbd.org/abc/${banner.image.url}')`,
            }}
          >
            <div className="container">
              <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">Our Products </h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li>
                      <Link to={'/'}>Home</Link>
                    </li>
                    <li>Our Products </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="content-block">
            {products &&
              products.map((item, index) => (
                <div className="section-full content-inner bg-white">
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-lg-6 col-md-12 m-b30">
                        <div className="our-story">
                          <span>Our Products</span>
                          <h2 className="title">{item?.name}</h2>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `${item?.details}`,
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 m-b30 ">
                        <img
                          src={`https://rest.alcumusbd.org/abc/${item?.images[0]?.url}`}
                          className="radius-sm"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            <div
              className="section-full content-inner bg-white wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.6s"
            >
              <div className="container">
                <div className="section-head text-black text-center">
                  <h2 className="title">News & Articles</h2>
                  <p></p>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <BlogSlider1 data={news} imgEffect="rotate" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer /> */}
      <div className="page-content bg-white">
        <div
          className="dlab-bnr-inr overlay-black-middle bg-pt"
          style={{ backgroundImage: 'url(' + bnr3 + ')' }}
        >
          <div className="container">
            <div className="dlab-bnr-inr-entry">
              <h1 className="text-white">Certificates </h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                    <Link to={'/'}>Home</Link>
                  </li>
                  <li>Our Certificates </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="content-block">
          {products &&
            products.map((item, index) => (
              <div className="section-full content-inner bg-white">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-12 col-md-12 m-b30">
                      <div className="our-story">
                        <span>Our Products</span>
                        <h2 className="title">{item?.name}</h2>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: `${item?.details}`,
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <div className="col-lg-6 col-md-12 m-b30 ">
                      <img
                        src={`https://rest.alcumusbd.org/abc/${item?.images[0]?.url}`}
                        className="radius-sm"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}

          <div
            className="section-full content-inner bg-white wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.6s"
          >
            <div className="container">
              <div className="section-head text-black text-center">
                <h2 className="title">News & Articles</h2>
                <p></p>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <BlogSlider1 data={news} imgEffect="rotate" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Products;
