import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const content = [
  {
    image: require('./../../images/our-team/team-bx/pic1.jpg'),
    name: 'Oliver',
    title: 'Director',
  },
  {
    image: require('./../../images/our-team/team-bx/pic2.jpg'),
    name: 'Alexander',
    title: 'Designer',
  },
  {
    image: require('./../../images/our-team/team-bx/pic3.jpg'),
    name: 'Hackson Willingham',
    title: 'Developer',
  },
  {
    image: require('./../../images/our-team/team-bx/pic4.jpg'),
    name: 'Konne Backfield',
    title: 'Manager',
  },
];

const TeamSlider2 = ({ team }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(4, team?.length),
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: Math.min(4, team?.length),
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    console.log('team', team);
  }, [team]);

  return (
    <>
      <Slider {...settings} className="team-carousel btn-style-1">
        {team &&
          team.map((item, id) => (
            <div
              className="item wow fadeInUp"
              data-wow-duration="2s"
              data-wow-delay="0.2s"
              key={id}
            >
              <div className="dlab-box m-b30 dlab-team7">
                <div className="dlab-media">
                  <Link to="#">
                    <img
                      width="358"
                      height="460"
                      alt=""
                      src={`https://rest.alcumusbd.org/abc/${item?.image?.url}`}
                    />
                  </Link>
                </div>
                {/* <div className="dlab-info">
                  <h4 className="dlab-title">
                    <Link to="#">{item.name}</Link>
                  </h4>
                  <span className="dlab-position">{item.designation}</span>
                  <ul className="dlab-social-icon">
                                    <li><Link className="site-button circle-sm gray-light fa fa-facebook" to="#"></Link></li>
                                    <li><Link className="site-button circle-sm gray-light fa fa-twitter" to="#"></Link></li>
                                    <li><Link className="site-button circle-sm gray-light fa fa-linkedin" to="#"></Link></li>
                                    <li><Link className="site-button circle-sm gray-light fa fa-pinterest" to="#"></Link></li>
                                </ul>
                </div> */}
              </div>
            </div>
          ))}
      </Slider>
    </>
  );
};

export default TeamSlider2;
