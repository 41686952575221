import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import Header from '../layout/header12';
import Footer from '../layout/footer';
import BlogSlider1 from '../element/blogSlider1';
import { useState } from 'react';
import { useEffect } from 'react';
import { getBanners } from '../server';
import { Link } from 'react-router-dom';
const bg = require('../../images/background/bg23.png');

function Contact1() {
  const [news, setNews] = useState(null);
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    getBanners({ type: 'contact us', subType: 'contact us' })
      .then((res) => {
        console.log('resAboutUs', res);
        if (res) {
          setBanner(res?.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const MyMapComponent = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        google={props.google}
        defaultZoom={18}
        defaultCenter={{
          lat: 23.79196450720995,
          lng: 90.40475745975287,
        }}
      >
        <Marker
          key={1}
          position={{ lat: 23.79196450720995, lng: 90.40475745975287 }}
        />
      </GoogleMap>
    ))
  );
  return (
    <>
      <Header />
      {/* {banner && (
        <div
          className="dlab-bnr-inr overlay-black-middle bg-pt"
          style={{
            backgroundImage: `url(' https://rest.alcumusbd.org/abc/${banner.image.url}')`,
          }}
        />
      )} */}
      <div
        className="dlab-bnr-inr overlay-black-middle bg-pt"
        style={{ backgroundImage: 'url(' + bg + ')', backgroundSize: 'cover' }}
      ></div>

      <div className="section-full p-t50 p-b20  text-white  footer-info-bar" style={{
        backgroundColor:"#008080"
      }}>
        <div className="container">
          <div className="dlab-bnr-inr-entry">
            <h1 className="text-white">Contact Us</h1>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
              <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1">
                <div className="icon-content">
                  <h5 className="dlab-tilte">
                    <span className="icon-sm">
                      <i className="ti-location-pin"></i>
                    </span>
                    Company Address
                  </h5>
                  <p className="op7">
                    House # 49, Flat-A3, Road #13, Block - D, Banani, Dhaka
                    -1213.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
              <div
                className="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1"
                style={{
                  height: '100%',
                }}
              >
                <div className="icon-content">
                  <h5 className="dlab-tilte">
                    <span className="icon-sm">
                      <i className="ti-email"></i>
                    </span>
                    E-mail
                  </h5>
                  <p className="m-b0 op7">info@alcumus.org</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
              <div
                className="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1"
                style={{
                  height: '100%',
                }}
              >
                <div className="icon-content">
                  <h5 className="dlab-tilte">
                    <span className="icon-sm">
                      <i className="ti-mobile"></i>
                    </span>
                    Phone Numbers
                  </h5>
                  <p className="m-b0 op7">Mobile : +88 01408-486297</p>
                  <p className="m-b0 op7">Phone : +88 02-4108526</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
              <div
                className="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1"
                style={{
                  height: '100%',
                }}
              >
                <div className="icon-content">
                  <h5 className="dlab-tilte">
                    <span className="icon-sm">
                      <i className="ti-alarm-clock"></i>
                    </span>
                    Office Hours
                  </h5>
                  <p className="m-b0 op7">Sat To Thurs - 09.00 AM-6.00 PM</p>
                  <p className="op7">Friday - Close</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content bg-white">
        <MyMapComponent
          isMarkerShown
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyB78hfZtM0bfvCzv3BqPoqI5wT6JkqJDtw&v=3.exp&libraries=geometry,drawing,places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `400px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>

      <Footer />
    </>
  );
}
export default Contact1;
