import React, { Component, useLayoutEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header';
import Footer from '../layout/footer';
import DatePicker from 'react-datepicker';
import '../../../node_modules/react-datepicker/dist/react-datepicker.css';
import { useEffect } from 'react';
import { deleteOrder, getCertificateByUser, getOrder, getUserById } from '../server';
import moment from 'moment';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import ReadMore from './readmore';
import { set } from 'react-hook-form';

const bg = require('../../images/background/bg24.png');

const numOfElements = 6;
function ShopWishlist() {
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [status, setStatus] = React.useState('requested');
  const [documents, setDocuments] = React.useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [imagesToshow, setImagesToshow] = useState([]);
  const [certificateNo, setCertificateNo] = useState('');

  const [user, setUser] = useState({});
  const id = localStorage.getItem('userId');

  
  useEffect(() => {

    
      getUserById({id})
      .then((res) => {
        console.log('rescert', res?.data);
       
        setCertificateNo(res?.data?.certificateNo);
      })
      .catch((err) => {
        console.log(err);
      });
  

  }, [])
  

   
   
 
   

  //   const handleDownload = async (pdfUrl) => {
  //     try {
  //         const response = await fetch(pdfUrl);
  //         if (!response.ok) {
  //             throw new Error(`HTTP error! status: ${response.status}`);
  //         }
  //         const blob = await response.blob();
  //         saveAs(blob, 'document.pdf');
  //     } catch (error) {
  //         console.error('Error downloading PDF:', error);
  //     }
  // };

  const handleDownload = (pdfUrl) => {
    // Create a hidden link to trigger the download
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.setAttribute('download', 'document.pdf');
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  // const handleDownload = (pdfUrl) => {
  //   try {
  //     // Create a hidden iframe to trigger the download
  //     const iframe = document.createElement('iframe');
  //     iframe.style.display = 'none';
  //     iframe.src = pdfUrl;

  //     document.body.appendChild(iframe);

  //     // Clean up after some time
  //     setTimeout(() => {
  //       document.body.removeChild(iframe);
  //     }, 5000); // Adjust this timeout as needed
  //   } catch (error) {
  //     console.error('Error downloading PDF:', error);
  //   }
  // };

  useEffect(() => {
    const fromDate = moment(startDate).format('YYYY-MM-DD');
    const toDate = moment(endDate).format('YYYY-MM-DD');
    getCertificateByUser()
      .then((res) => {
        console.log('resAboutUs', res.data);
        setDocuments(res.data);
        setTotalPages(Math.ceil(res.data.length / numOfElements));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const paginatedData = useMemo(() => {
    return documents?.slice(
      (currentPage - 1) * numOfElements,
      currentPage * numOfElements
    );
  }, [documents, currentPage]);

  useEffect(() => {
    if(certificateNo){
      console.log('certificateNo', certificateNo);
    }
  }, [certificateNo])
  

  return (
    <>
  <div className="page-content bg-white">
    <div
      className="dlab-bnr-inr overlay-black-middle bg-pt"
      style={{ backgroundImage: 'url(' + bg + ')' }}
    >
      <div className="container">
        <div className="dlab-bnr-inr-entry">
        <div className='col' style={{
          textAlign: 'left',
        }}>
        <h1 className="text-white">Your Certificates</h1>
      {
        certificateNo && <h6 className="text-white" style={{
          textAlign: 'left',
          marginTop: '10px',
          marginLeft: '10px',
        }}>Certificate No : {certificateNo}</h6>
      }
          </div>
          <div className="breadcrumb-row">
            <ul className="list-inline">
              <Link to="/">
                <li>Home{'>'} </li>
              </Link>
              <Link
                onClick={() => {
                  localStorage.removeItem('userId');
                  localStorage.removeItem('access_token');
                  window.location.href = '/login-page';
                }}
              >
                <li>Log Out</li>
              </Link>
            </ul>
          </div>
        </div>
      </div>
    </div>

    {/* create a nice little place for rendering cards with pdf preview  */}

    <div className="section-full content-inner shop">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="shop-detail">
              <div className="row">
                {paginatedData?.map((item) => (
                  <div
                    className="col-lg-4 col-md-6 col-sm-6 m-b30"
                    key={item.id}
                  >
                    <div className="dlab-box">
                      <div className="dlab-media ">
                        {/* Remove iframe borders */}
                        <iframe
                          title="PDF Preview"
                          src={`https://rest.alcumusbd.org/abc/${item?.document?.url}`}
                          style={{
                            width: '100%',
                            height: '400px',
                            border: 'none',
                          }}
                        />
                      </div>
                      <div className="dlab-info p-a30 border-1">
                        <div className="dlab-post-title">
                          <h4 className="post-title">
                            <Link to="#">{item?.documentName}</Link>
                          </h4>
                        </div>
                        <div className="dlab-post-text">
                          {item?.remarks && (
                            <ReadMore text={item?.remarks} />
                          )}
                        </div>
                        <div className="dlab-post-text">
                          <p>
                            <h6>
                              {`Expiry Date: ${moment(item.expireDate).format('DD-MM-YYYY')}`}
                            </h6>
                          </p>
                        </div>
                        <div className="dlab-post-readmore">
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          >
                            <button
                              onClick={() =>
                                handleDownload(
                                  `https://rest.alcumusbd.org/abc/${item?.document?.url}`
                                )
                              }
                              className="site-button"
                            >
                              Download
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="pagination-bx clearfix  col-md-12 text-center">
      <ul className="pagination" style={{ justifyContent: 'center' }}>
        <li className="previous">
          <Link
            to={'#'}
            onClick={() => {
              if (currentPage > 1) {
                setCurrentPage(currentPage - 1);
              }
            }}
          >
            <i className="ti-arrow-left"></i> Prev
          </Link>
        </li>
        {[...Array(totalPages)].map((item, index) => (
          <li
            className={currentPage === index + 1 ? 'active' : ''}
            key={index}
          >
            <Link to={'#'} onClick={() => setCurrentPage(index + 1)}>
              {index + 1}
            </Link>
          </li>
        ))}

        <li className="next">
          <Link to={'#'} onClick={() => setCurrentPage(currentPage + 1)}>
            Next <i className="ti-arrow-right"></i>
          </Link>
        </li>
      </ul>
    </div>
  </div>
</>

  );
}
export default ShopWishlist;
